import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

type ModalErrorProps = {
  message: string
  onCloseModalError: () => void
}

export const ModalError = ({ message, onCloseModalError }: ModalErrorProps) => {
  return (
    <Dialog open={Boolean(message)} onClose={onCloseModalError}>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCloseModalError}
          variant={'contained'}
          size={'small'}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
