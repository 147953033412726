import {
  DialogContentText,
  DialogActions,
  DialogContent,
  Button,
  Dialog,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/system'
import { useState } from 'react'

import { useDataset } from 'features/datasets'

import { CheckboxWithInputSeconds } from '../molecules/checkbox-with-input-seconds'
import { LanguageButtonsGroup } from '../molecules/language-buttons-group'
import { TextFieldName } from '../atoms/text-field-name'
import { textCheckboxes } from '../utils'
import { Loader } from '../atoms/loader'


type ModalAudioProps = {
  isModal: boolean
  setIsModal: (value: any) => void
  handle: (audio: AudioSet) => void
  files: File[]
}

export type AudioSet = {
  text: boolean
  slice: boolean
  seconds: number
  language: string
  datasetName: string
  restoreCase: boolean
  addTranslate: boolean
  deleteSilenсe: boolean
  convertDigits: boolean
  addPunсtuation: boolean
}

export const ModalAudio = observer(
  ({ isModal, setIsModal, handle, files }: ModalAudioProps) => {
    const defaultName = new Date().toString().slice(0, 15)
    const audioCheckboxes = {
      seconds: 0,
      text: false,
      slice: false,
      language: 'ru',
      restoreCase: false,
      addTranslate: false,
      convertDigits: false,
      deleteSilenсe: false,
      addPunсtuation: false,
      datasetName: defaultName,
    }
    const [newAudioCheckboxes, setNewAudioCheckboxes] =
      useState<AudioSet>(audioCheckboxes)
			

    const onChange = (
      type:
        | 'deleteSilenсe'
        | 'slice'
        | 'text'
        | 'addPunсtuation'
        | 'convertDigits'
        | 'addTranslate'
        | 'restoreCase'
    ) => {
      setNewAudioCheckboxes({
        ...newAudioCheckboxes,
        [type]: !newAudioCheckboxes[type],
      })
    }

    const onChangeValue = (type: string, value: string) => {
      switch (type) {
        case 'seconds':
          setNewAudioCheckboxes({
            ...newAudioCheckboxes,
            seconds: Number(value),
          })
          break
        case 'language':
          setNewAudioCheckboxes({
            ...newAudioCheckboxes,
            'language': value,
          })
					break
					case 'datasetName':
          setNewAudioCheckboxes({
            ...newAudioCheckboxes,
            'datasetName': value,
          })
					break
      }
    }

    const dataset = useDataset()

    const closeModal = () => {
      setIsModal(false)
      setNewAudioCheckboxes(audioCheckboxes)
    }

    const handleClose = () => {
      if (
        !newAudioCheckboxes.deleteSilenсe &&
        !newAudioCheckboxes.slice &&
        !newAudioCheckboxes.text
      ) {
        setIsModal(false)
        dataset.onCreate(files, newAudioCheckboxes.datasetName)
        setNewAudioCheckboxes(audioCheckboxes)
      } else {
        handle(newAudioCheckboxes)
        setNewAudioCheckboxes(audioCheckboxes)
      }
    }

    return (
      <>
        <Dialog open={isModal} onClose={closeModal}>
          <DialogContent>
            <DialogContentText
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Box sx={{ alignSelf: 'center' }}>
                <TextFieldName
                  defaultName={defaultName}
                  onChange={(value) => onChangeValue('datasetName', value)}
                />
              </Box>
              <Box>
                <FormControlLabel
                  label="Удаление тишины"
                  control={
                    <Checkbox
                      onChange={() => onChange('deleteSilenсe')}
                      checked={newAudioCheckboxes.deleteSilenсe}
                    />
                  }
                />
                <Box>
                  <CheckboxWithInputSeconds
                    onChangeValue={onChangeValue}
                    onChange={onChange}
                    seconds={newAudioCheckboxes.seconds.toString()}
                    slice={newAudioCheckboxes.slice}
                  />
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <FormControlLabel
                    label={'Отекстовка'}
                    control={
                      <Checkbox
                        onChange={() => onChange('text')}
                        checked={newAudioCheckboxes.text}
                      />
                    }
                  />
                  {newAudioCheckboxes.text && (
                    <LanguageButtonsGroup
                      onChange={onChangeValue}
                      active={newAudioCheckboxes.language === 'ru'}
                    />
                  )}
                </Box>
                {newAudioCheckboxes.text && (
                  <>
                    {textCheckboxes.map((checkbox) => (
                      <FormControlLabel
                        label={checkbox.value}
                        control={
                          <Checkbox
                            onChange={() => onChange(checkbox.title)}
                            checked={newAudioCheckboxes[checkbox.title]}
                          />
                        }
                      />
                    ))}{' '}
                  </>
                )}
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              component={'label'}
              variant={'contained'}
              disabled={
                newAudioCheckboxes.slice && !Boolean(newAudioCheckboxes.seconds)
              }
            >
              Создать
            </Button>
          </DialogActions>
        </Dialog>
        <Loader isLoader={!!dataset.fetch} />
      </>
    )
  }
)
