import { Box, Paper, styled, Typography } from '@mui/material'


type TopPanelprops = {
  all: number
  skipped: number
}

const StyledBox = styled(Box)({
  width: '300px',
  height: '50px',
  padding: '16px',
  display: 'flex',
  borderRadius: '8px',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const TopPanel = ({ all, skipped }: TopPanelprops) => (
  <Box
    mb={3}
    gap={3}
    display={'flex'}
    flexDirection={'row'}
    justifyContent={'flex-start'}
  >
    <Paper>
      <StyledBox>
        <Typography color={'text.secondary'}>Всего разметок: </Typography>
        <Typography variant="h1">{all}</Typography>
      </StyledBox>
    </Paper>
    <Paper>
      <StyledBox>
        <Typography color={'text.secondary'}>Пропущено:</Typography>
        <Typography color={'warning.main'} variant="h1">
          {skipped}
        </Typography>
      </StyledBox>
    </Paper>
  </Box>
)
