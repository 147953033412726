import axios from 'axios'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'

import { DOMEN } from 'api'
import { token } from 'features/token'
import { Page, PageTitleProject } from 'shared'
import { UsersTable } from 'features/users-table'

import usersState from './state'


export const Users = observer(() => {
  const { projectId } = useParams()

  useEffect(() => {
    axios
      .get(`${DOMEN}/api/users/`, { headers: token.headers })
      .then((res) => usersState.setUsers(res.data.results))
    axios
      .get(`${DOMEN}/api/role/`, { headers: token.headers })
      .then((res) =>
        usersState.setRoles(
          res.data.results.filter((el: any) => el.id === 5 || el.id === 7)
        )
      )
    axios
      .get(`${DOMEN}/api/role_user_project/`, { headers: token.headers })
      .then((res) => usersState.setModifyRolesUserProject(res.data.results))
  }, [])

  const users = usersState.users.map((user) => ({
    ...user,
    role:
      user.newRole ||
      usersState.rolesUserProject
        .filter(
          (rop) => rop.userId === user.id && rop.projectId === Number(projectId)
        )
        .map((rop) => rop.roleId)
        .at(0),
  }))

  useEffect(() => {
    const timer = setTimeout(() => {
      usersState.wasSaved()
    }, 2000)
    return () => clearTimeout(timer)
  }, [users])

  return (
    <Page>
			<PageTitleProject />
      <UsersTable
        users={users}
        roles={usersState.roles.slice().reverse()}
        onCheckToggle={useCallback(
          (userId) => usersState.onCheckToggle(userId),
          []
        )}
        onChangeRole={useCallback(
          (id, value, oldRole?) =>
            usersState.onUserChange(
              id,
              'newRole',
              value,
              oldRole,
              Number(projectId)
            ),
          []
        )}
        onChangeRating={useCallback(
          (id, value) => usersState.onUserChange(id, 'rating', value),
          []
        )}
      />
    </Page>
  )
})
