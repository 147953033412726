import { Components, Theme } from '@mui/material'

export default {
  defaultProps: {
    size: 'small',
  },
  variants: [
    {
      props: { type: 'color' },
      style: ({ theme }) => ({
        width: 20,
        height: 20,
        borderRadius: '50%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'var(--color)',
        transition: theme.transitions.create('box-shadow'),

        ':hover, :focus-within': {
          boxShadow: `0 0 0 2px ${theme.palette.action.focus}`,
        },

        input: { opacity: 0 },
      }),
    },
    {
      props: { variant: 'outlined', 'data-inline': true },
      style: () => ({
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiInputAdornment-root': {
          visibility: 'hidden',
        },
        ':where(:hover,:focus-within) .MuiInputAdornment-root': {
          visibility: 'visible',
        },
      }),
    },
  ],
} as Components<Theme>['MuiTextField']
