import { observer } from 'mobx-react-lite'
import { Checkbox, Typography, IconButton } from '@mui/material'
import { IconLink, Icon, Status } from 'shared'
import { useDataset, usePage } from 'features/datasets'
import { Link as LinkRouter } from 'react-router-dom'

export const ChboxHead = observer(() => {
  const page = usePage()

  return <Checkbox {...page.checkbox} />
})

export const CountSelected = observer(() => <>{usePage().selected.length}</>)

export const ChboxRow = observer(() => {
  const ds = useDataset()
  return (
    <Checkbox
      checked={ds.selected}
      onChange={({ target }) => ds.setSelected(target.checked)}
    />
  )
})

export const Link = observer(() => {
  const ds = useDataset()

  return (
    <IconLink
      icon={'settings'}
      to={String(ds.id)}
      data-disabled={Boolean(ds.fetch)}
    />
  )
})

export const Type = observer(() => {
  const ds = useDataset()

  return <Icon icon={ds.type} data-type={ds.type} />
})

export const Name = observer(() => (
  <LinkRouter to={`${useDataset().id}/markups/`}>
    <Typography fontWeight={500} children={useDataset().name} />
  </LinkRouter>
))

export const Records = observer(() => (
  <Typography children={useDataset().total.records} />
))

export const Markups = observer(() => (
  <Typography color={'primary.main'} children={useDataset().total.markups} />
))

export const Skiped = observer(() => (
  <Typography color={'warning.main'} children={useDataset().total.skiped} />
))

export const Date = observer(() => (
  <Typography color={'text.secondary'} children={useDataset().date} />
))

export const Remove = observer(() => {
  const ds = useDataset()

  return (
    <IconButton
      data-hidden
      children={<Icon icon={'remove'} />}
      onClick={() => ds.onRemove()}
    />
  )
})

export const State = observer(() => {
  const ds = useDataset()

  return (
    <Status
      status={ds.state}
      onClick={() => ds.onStateChange()}
      disabled={ds?.state !== 2 && ds?.state !== 3}
    />)
	}
  )
