import { observer } from 'mobx-react-lite'
import * as Mui from '@mui/material'
import * as Shared from 'shared'
import {
  ClassProvider,
  useClasses,
  useClass,
  useMarkup,
} from 'features/page-markup'
import { useOverClassesScroll } from './hooks'
import { FCC } from 'types'

const List = Mui.styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  padding: theme.spacing(0, 3),
  overflow: 'auto',
  outline: 0,
  ':focus': {
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.light}`,
  },
}))

const Template = Mui.styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(1),
  alignItems: 'center',
  position: 'relative',
}))

const Skeleton = observer(() => (
  <Template>
    <Mui.Skeleton variant={'button-class'} />
    <Mui.Skeleton variant={'button'} />
  </Template>
))

const Button: FCC = observer((props) => {
  const { onOver, onOut, onRemove } = useClass()

  return (
    <Mui.Button
      variant={'class'}
      children={props.children}
      onFocus={onOver}
      onBlur={onOut}
      onClick={onRemove}
    />
  )
})

const Field = observer(() => {
  const { onOver, onOut } = useClass()

  return (
    <Mui.TextField select onFocus={onOver} onBlur={onOut}>
      <Mui.MenuItem />
    </Mui.TextField>
  )
})

const Arrow = observer(() => {
  const cls = useClass()
  const clss = useClasses()

  return cls.isOver && !clss.isOver ? (
    <Shared.Icon
      icon={'arrow_forward'}
      color={'disabled'}
      sx={{
        position: 'absolute',
        transform: 'translateX(-100%)',
        left: 0,
      }}
    />
  ) : null
})

const ListItem: FCC = observer((props) => (
  <Template
    ref={useOverClassesScroll()}
    onMouseOut={useClass().onOut}
    onMouseOver={useClass().onOver}
    children={props.children}
  />
))

const SelectionsList = observer(() => {
  const { items, onOut, onOver } = useClasses()

  return (
    <List onMouseOver={onOver} onMouseOut={onOut} onKeyDown={onOver}>
      {items.map((item, i) => (
        <ClassProvider key={item.key} value={item}>
          <ListItem>
            <Arrow />
            <Button>{i + 1}</Button>
            <Field />
          </ListItem>
        </ClassProvider>
      ))}
    </List>
  )
})

export const Selections = observer(() =>
  useMarkup().isDisabled ? (
    <List>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </List>
  ) : (
    <SelectionsList />
  )
)
