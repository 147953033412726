import { observer } from 'mobx-react-lite'
import * as Atom from '../atoms'
import { Collapses } from './collapses'

export const BlockSettings = observer(() => (
  <Atom.Block>
    <Atom.Title>Общие настройки</Atom.Title>
    <Collapses />
  </Atom.Block>
))
