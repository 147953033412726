import { observer } from 'mobx-react-lite'
import { Grid, Page, PageTitleProject } from 'shared'
import { PageProvider } from 'features/catalogs'
import { template } from './grid-cells'
import { GridHead } from './grid-head'
import { NewCatalogButton } from './buttons'
import { GridBody } from './grid-body'

export const Catalogs = observer(() => (
  <PageProvider>
    <Page>
      <PageTitleProject />
      <NewCatalogButton />
      <Grid sx={{ '--template': template }}>
        <GridHead />
        <GridBody />
      </Grid>
    </Page>
  </PageProvider>
))
