import {
  TableRow,
  Checkbox,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'


export type UsersTableHeaderProps = {
  value: boolean
  orderBy: string
  order: 'asc' | 'desc'
  indeterminate: boolean
  onSort: (property: string) => void
  onCheckAll: (value: boolean) => void
}

export const UsersTableHeader = ({
  value,
  order,
  orderBy,
  indeterminate,
  onSort,
  onCheckAll,
}: UsersTableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <TableSortLabel
            children={
              <Checkbox
                checked={value}
                indeterminate={indeterminate}
                onChange={(evt) => onCheckAll(evt.target.checked)}
              />
            }
            active={orderBy === 'checked'}
            IconComponent={ArrowDropDown}
            onClick={() => onSort('checked')}
            direction={orderBy === 'checked' ? order : 'asc'}
          />
        </TableCell>
        <TableCell> </TableCell>
        <TableCell align="left">
          <TableSortLabel
            children={'Имя'}
            IconComponent={ArrowDropDown}
            active={orderBy === 'username'}
            onClick={() => onSort('username')}
            direction={orderBy === 'username' ? order : 'asc'}
          />
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            children={'Роль'}
            active={orderBy === 'role'}
            IconComponent={ArrowDropDown}
            onClick={() => onSort('role')}
            direction={orderBy === 'role' ? order : 'asc'}
          />
        </TableCell>
        <TableCell align="center">
          <TableSortLabel
            children={'Email'}
            active={orderBy === 'email'}
            IconComponent={ArrowDropDown}
            onClick={() => onSort('email')}
            direction={orderBy === 'email' ? order : 'asc'}
          />
        </TableCell>
        <TableCell align="right">
          <TableSortLabel
            children={'Рейтинг (%)'}
            active={orderBy === 'rating'}
            IconComponent={ArrowDropDown}
            onClick={() => onSort('rating')}
            direction={orderBy === 'rating' ? order : 'asc'}
          />
        </TableCell>
        <TableCell align="right">
          <TableSortLabel
            children={'В проекте'}
            active={orderBy === 'role'}
            IconComponent={ArrowDropDown}
            onClick={() => onSort('role')}
            direction={orderBy === 'role' ? order : 'asc'}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
