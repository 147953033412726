import { observer } from 'mobx-react-lite'
import { Paper } from '@mui/material'
import { NewProjectProvider, ProjectProvider, usePage, useProject } from 'features/projects'
import { ProjectLoader } from './loaders'
import { Main, Edit, Remove } from './views'

const Project = observer(() => {
  const project = useProject()

  return (
    <Paper
      variant={'project'}
      sx={{ boxSizing: project.view === 'edit' ? 'content-box' :'inherit' }}
    >
      <Main />
      <Edit />
      <Remove />
      <ProjectLoader />
    </Paper>
  )
})

export const NewProject = observer(() => {
  const page = usePage()

  return page.showNewProject ? (
    <NewProjectProvider>
      <Project />
    </NewProjectProvider>
  ) : null
})

export const ProjectList = observer(() => {
  const page = usePage()

  return (
    <>
      {page.list.filter(p => !p.is_template).map((project) => (
        <ProjectProvider key={project.id} value={project}>
          <Project />
        </ProjectProvider>
      ))}
    </>
  )
})
