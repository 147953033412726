import { createContext, useContext, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useFetch } from 'api'
import { FCC } from 'types'
import { Page } from './page'
import { Dataset } from './dataset'

//================ page datasets

const PageContext = createContext({} as Page)

export const usePage = () => useContext(PageContext)

export const DatasetsPageProvider: FCC = observer((props) => {
  const page = useRef(new Page()).current
  useFetch(page.fetch)

  return <PageContext.Provider value={page} {...props} />
})

//================ dataset

const DatasetContext = createContext({} as Dataset)

export const useDataset = () => useContext(DatasetContext)

export const DatasetProvider: FCC<{ value: Dataset }> = observer((props) => {
  useFetch(props.value.fetch)

  return <DatasetContext.Provider {...props} />
})

//================ new dataset

export const NewDatasetProvider: FCC = observer((props) => {
  const dataset = useRef(new Dataset()).current
  useFetch(dataset.fetch)

  return <DatasetContext.Provider value={dataset} {...props} />
})
