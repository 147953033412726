import { makeAutoObservable } from 'mobx'
import { TextFieldProps as P } from '@mui/material'
import * as uuid from 'uuid'

export class FieldState {
  props: P = { key: uuid.v4() }

  constructor(label = '', value = '') {
    this.props.label = label
    this.props.value = value
    this.props.onChange = ({ target }) => this.set({ value: target.value })

    makeAutoObservable(this)
  }

  set = (props: P) => {
    Object.assign(this.props, props)
  }

  get value(): string {
    return String(this.props.value)
  }
}
