import {
  entries,
  has,
  makeAutoObservable,
  observable,
  values,
  set,
  keys,
} from 'mobx'

export class ClassesState {
  map = observable.map<string, ClassState>()

  constructor() {
    this.map.set('ROOT', new ClassState())

    makeAutoObservable(this)
  }

  addClass = (key: string) => {
    if (has(this.map, key)) return

    set(this.map, { [key]: new ClassState() })
  }

  removeClass = (key: string) => {
    this.map.delete(key)
  }

  get object() {
    return Object.fromEntries(
      entries(this.map).map(([key, c]) => [key, c.object])
    )
  }

  get list() {
    return values(this.map).flatMap((c) => values(c.properties))
  }

  get keys() {
    return keys(this.map) as string[]
  }
}

export class ClassState {
  properties = observable.map<string, PropertyState>()

  constructor() {
    makeAutoObservable(this)
  }

  get object() {
    if (!this.properties.size) return {}

    return {
      properties: Object.fromEntries(
        entries(this.properties).map(([key, p]) => [key, p.object])
      ),
    }
  }
}

const types = ['CHECK_BOX', 'COMBO_BOX', 'INPUT_BOX_TEXT'] as const

type Type = typeof types[number]

export class PropertyState {
  types = types

  constructor(
    public classKey = '',
    public key = '',
    public type: Type = 'CHECK_BOX',
    public label = '',
    public order = 0,
    public catalogId?: number
  ) {
    makeAutoObservable(this)
  }

  set = (prop: Partial<PropertyState>) => {
    Object.assign(this, prop)
  }

  get settings() {
    if (!this.catalogId) return null

    return {
      values: { catalog_id: this.catalogId },
    }
  }

  get isValid() {
    return [
      this.key,
      this.classKey,
      this.type,
      this.label,
      this.type === 'COMBO_BOX' ? this.catalogId : true,
    ].every(Boolean)
  }

  get object() {
    return {
      type: this.type,
      label: this.label,
      order: this.order,
    }
  }
}
