import { Icon } from "shared";


export const Arrow = ({ focus }: ArrowProps) => focus ? (
  <Icon
    icon={'arrow_forward'}
    color={'disabled'}
    sx={{
      position: 'absolute',
      transform: 'translateX(-100%)',
      left: 0,
    }}
  />
) : <></>
