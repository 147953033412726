import { makeAutoObservable, observable, values } from 'mobx'
import { Config, MarkupComponents } from 'types'
import { toJsonString } from 'utils'

interface Item extends Omit<Property, 'settings'> {
  classKey: string
  propKey: string
  catalogId: number
}

const createItem =
  (classKey: string) =>
  ([propKey, p]: [string, Property]): Item => ({
    classKey,
    propKey,
    label: p.label,
    order: p.order,
    type: p.type,
    catalogId: p.settings?.values.catalog_id ?? NaN,
  })

export class ConfigState {
  data?: Config
  arr = observable.array<Item>()
  category = false
  entity = false
  catalogId?: number
  dataType = ''
  dataValue = ''
  item: Partial<Item> = {}

  constructor() {
    makeAutoObservable(this)
  }

  setConfig = (config: Config) => {
    const { markup_components: mc, display_component: dc } = config

    this.data = config
    this.catalogId = mc.class_values?.catalog_id
    this.entity = Number.isInteger(this.catalogId)
    this.dataType = dc.data.type
    this.dataValue = dc.data.value

    const arr = Array.from(Object.entries(mc.classes)).flatMap(
      ([c, { properties }]) =>
        Array.from(Object.entries(properties)).map(createItem(c))
    )

    console.log(arr)

    this.category = this.arr.some((i) => i.classKey === 'ROOT')

    for (const i of this.arr) {
      console.log(i)
    }
  }

  get items() {
    return values(this.arr)
      .filter((i) => (!this.category ? i.classKey !== 'ROOT' : i))
      .filter((i) => (!this.entity ? i.classKey !== 'COMMON' : i))
  }

  get classes() {
    return this.items.reduce((obj, item) => {
      const { classKey, propKey, label, order, type, catalogId } = item

      const properties = Object.assign(obj[classKey].properties ?? {}, {
        [propKey]: {
          label,
          order,
          type,
          ...(catalogId
            ? { settings: { values: { catalog_id: catalogId } } }
            : {}),
        } as Property,
      })

      obj[classKey] = { properties }

      return obj
    }, {} as MarkupComponents['classes'])
  }

  get object() {
    if (!this.data) return {}

    const { meta, display_component } = this.data

    return {
      meta,
      display_component: {
        ...display_component,
        enable_entity_selection: this.entity,
        data: { type: this.dataType, value: this.dataValue },
      },
      markup_components: {
        classes: this.classes,
        class_values: this.entity ? { catalog_id: this.catalogId } : undefined,
      },
    } as Config
  }

  get json() {
    return toJsonString(this.object)
  }

  get classKeys() {
    const set = new Set(
      this.items
        .map((i) => i.classKey)
        .concat(this.category ? 'ROOT' : '')
        .concat(this.entity ? 'COMMON' : '')
        .filter(Boolean)
    )

    return Array.from(set.values())
  }
}
