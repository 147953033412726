import * as RRD from 'react-router-dom'
import * as Page from 'pages'
import { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'


export const Auth = () =>
  RRD.useRoutes([
    { index: true, element: <Page.Projects /> },
    { path: 'users', element: <Page.Users /> },
    { path: 'catalogs', element: 'all catalogs' },
    {
      path: 'project/:projectId',
      element: <Page.Project />,
      children: [
        { path: 'catalogs', element: <Page.Catalogs /> },
        { path: 'datasets', element: <Page.Datasets /> },
        { path: 'datasets/:datasetId', element: <Page.SettingsDataset /> },
        {
          path: 'datasets/:datasetId/markups',
          element: <Page.DatasetMarkupsTable />,
        },
        {
          path: 'datasets/:datasetId/markups/:markupId',
          element: <Page.OneMarkup />,
        },
        { path: 'markup', element: <Page.Markup /> },
        { path: 'users', element: <Page.Users /> },
        { path: '*', element: <Page.Replace to="" /> },
        { index: true, element: <Page.Replace to="datasets" /> },
      ],
    },
    { path: '*', element: <Page.Replace to="/" /> },
  ])

export const NotAuthRedirect = () => {
  const { keycloak } = useKeycloak()

  useEffect(() => {
    if (keycloak.authServerUrl) {
      keycloak.login()
    }
  })

  return null
}

export const NotAuth = () =>
  RRD.useRoutes([{ path: '*', element: <NotAuthRedirect /> }])
