import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, IconButton } from '@mui/material'
import { Icon } from 'shared'
import {
  patch,
  setPatch,
  useProp,
  setJsonView,
  validationError,
  loading,
} from 'features/settings-dataset'

export const NewPropBtn = observer(() => {
  const prop = useProp()

  return (
    <Button
      children={'Новое свойство'}
      disabled={prop.disabledNewButton}
      onClick={() => prop.setForm(true)}
    />
  )
})

export const EditBtn = observer(() => {
  const prop = useProp()

  return (
    <IconButton
      data-hidden
      sx={{ position: 'absolute', placeSelf: 'start end' }}
      onClick={() => prop.setForm(true)}
    >
      <Icon icon={'edit'} />
    </IconButton>
  )
})

export const RemoveBtn = observer(() => {
  const prop = useProp()

  if (prop.isNew) return null

  return (
    <IconButton onClick={() => prop.onRemove()}>
      <Icon icon={'remove'} />
    </IconButton>
  )
})

export const CloseBtn = observer(() => {
  const prop = useProp()

  return (
    <IconButton onClick={() => prop.onClose()}>
      <Icon icon={'close'} />
    </IconButton>
  )
})

export const DoneBtn = observer(() => {
  const prop = useProp()

  return (
    <IconButton
      form={'prop-form'}
      type={'submit'}
      color={'primary'}
      disabled={prop.disabledForm}
      onClick={() => prop.onSubmit()}
    >
      <Icon icon={'done'} />
    </IconButton>
  )
})

export const SaveBtn = observer(() => (
  <IconButton
    color={'info'}
    onClick={() => setPatch(true)}
    disabled={patch.get() || loading.get()}
  >
    <Icon icon={'save'} />
  </IconButton>
))

export const DataBtn = observer(() => (
  <IconButton onClick={() => setJsonView('data')} disabled={loading.get()}>
    <Icon icon={'json'} />
  </IconButton>
))

export const JsonBtn = observer(() => (
  <IconButton onClick={() => setJsonView('settings')} disabled={loading.get()}>
    <Icon icon={'json_setting'} />
  </IconButton>
))

export const ErrorBtn = observer(() => {
  const error = validationError.get()
  useEffect(() => { setJsonView('error') }, [error])
  return (
    <IconButton
      color={'error'}
      onClick={() => setJsonView('error')}
      disabled={!validationError.get() || loading.get()}
    >
      <Icon icon={'error'} />
    </IconButton>
  )
})
