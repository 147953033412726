import { Outlet } from 'react-router-dom'
import { ProjectPageProvider } from 'features/projects'
import { DatasetsPageProvider } from 'features/datasets'

export const Project = () => (
  <ProjectPageProvider>
    <DatasetsPageProvider>
      <Outlet />
    </DatasetsPageProvider>
  </ProjectPageProvider>
)
