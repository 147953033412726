import axios from 'axios'

import { toJS } from 'mobx'
import * as uuid from 'uuid'
import { mapValues } from 'lodash'
import * as Mui from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { DOMEN } from 'api'
import * as Shared from 'shared'
import { token } from 'features/token'

import { TimeBox } from 'control/time-box'
import { ReasonsPanel } from 'control/reasons-panel'
import { ControlAsyncLoader } from 'control/async-loader'
import { Choositor, Select, Selector, utils } from 'markers/selectors'
import { CancelNextButtons } from 'control/reasons-panel/cancel-next-buttons'

import { getTime } from './utils'
import markupProccess from '../markup/showers/state'


export const OneMarkup = observer(() => {
  const { projectId } = useParams()
  const [time, setTime] = useState('')
  const [reason, setReason] = useState('')
  const [action, setAction] = useState(1)
  const { datasetId, markupId } = useParams()
  const [record, setRecord] = useState<any>()
  const [markup, setMarkup] = useState<any>()
  const [isEdit, setIsEdit] = useState(false)
  const [users, setUsers] = useState<any>([])
  const [roles, setRoles] = useState<any>([])
  const [dataset, setDataset] = useState<any>()
  const [categories, setCategories] = useState<Category[]>([])
  const [usersOnProject, setUsersOnProject] = useState<any>([])

  const actions = [
    { pk: 1, name: 'Отклонить' },
    { pk: 2, name: 'Отправить на доработку' },
  ]

  const clear = () => {
    setTime('')
    setRecord(undefined)
    setMarkup(undefined)
    setDataset(undefined)
    setCategories([])
    markupProccess.clear()
  }

  useEffect(() => {
    axios
      .get(`${DOMEN}/api/audio_markups/${markupId}/`, {
        headers: token.headers,
      })
      .then((res) => {
        if (res.statusText === 'OK' && res?.data) {
          setMarkup(res.data)
          axios
            .get(`${DOMEN}/${res.data.record.split('/').slice(3).join('/')}`, {
              headers: token.headers,
            })
            .then(
              (res) =>
                res.statusText === 'OK' && res?.data && setRecord(res.data)
            )
          axios
            .get(`${DOMEN}/${res.data.task.split('/').slice(3).join('/')}`, {
              headers: token.headers,
            })
            .then((res) => {
              if (res.statusText === 'OK' && res?.data) {
                setTime(getTime(res.data))
              }
            })
        }
      })
    axios
      .get(`${DOMEN}/api/audio_dataset/${datasetId}/`, {
        headers: token.headers,
      })
      .then(
        (res) => res.statusText === 'OK' && res?.data && setDataset(res.data)
      )
    axios
      .get(`${DOMEN}/api/audio_category/?page_size=100000`, {
        headers: token.headers,
      })
      .then(
        (res) =>
          res.statusText === 'OK' &&
          res?.data &&
          setCategories(
            res.data.results.map((cat: any) => ({
              pk: Number(cat.id),
              name: cat.name,
              color: cat.color,
              description: cat.description,
              examples: cat.examples,
              parent: cat.parent && {
                pk: cat.parent.split('/').slice(-2, -1)[0],
              },
            }))
          )
      )
    axios
      .get(`${DOMEN}/api/role/`, { headers: token.headers })
      .then((res) => setRoles(res.data.results))

    axios
      .get(`${DOMEN}/api/users/`, { headers: token.headers })
      .then((res) => setUsers(res.data.results))

    axios
      .get(`${DOMEN}/api/role_user_project/`, { headers: token.headers })
      .then((res) => setUsersOnProject(res.data.results))
    return () => clear()
  }, [markupId])

  const class_values = utils.categoriesFromParent(
    categories,
    utils.classId(dataset?.config?.markup_components)
  )

  const setDefaultMarkup = () => {
    if (markup && categories) {
      markupProccess.setDefaultCategory(class_values[0])
      const propertiesROOT = markup.data.entities.find(
        (ent: any) => ent.class.class_id === 'ROOT'
      )?.properties
      markupProccess.setRootSelections(
        mapValues(propertiesROOT, (o: any) => o.value)
      )

      const common = markup.data.entities.filter(
        (ent: any) => !isNaN(+ent.class.class_id)
      )
      markupProccess.setCommonSelections(
        common.map((com: any) => ({
          uuid: uuid.v4(),
          selection: com.selection,
          category: categories.find(
            (cat) => cat.pk === Number(com.class.class_id)
          ),
          properties: mapValues(com.properties, (o: any) => o.value),
        }))
      )
    }
  }

  useEffect(() => {
    setDefaultMarkup()
  }, [markup, categories])

  // получаем имя юзера и роль
  const userOnProject = usersOnProject?.filter(
    (obj: any) =>
      obj?.user === markup?.user &&
      obj.project.split('/').slice(-2, -1)[0] === projectId
  )[0]
  const roleName = roles.filter(
    (role: any) =>
      role?.url.split('/').slice(-2, -1)[0] ===
      userOnProject?.role.split('/').slice(-2, -1)[0]
  )[0]?.name
  const userName = users.filter(
    (user: any) => user?.url === userOnProject?.user
  )[0]?.username

  // редактирование разметки
  const onChange = (sel: SelectT) => {
    markupProccess.modifySelection(sel)
  }
  const onChangeMagic = (selects: SelectT[]) => {
    markupProccess.modifySelectionMagic(selects)
  }
  const onRemove = (index: number | string) => {
    markupProccess.onRemove(index)
  }
  const onChangeChoositor = (propertyKey: string, value: Value) => {
    markupProccess.setRootSelection(propertyKey, value)
  }
  const handler = (element: SelectionT, type: string, value?: Value) => {
    markupProccess.onSelectionChange(element, type, categories, value)
  }

  const onChangeDefaultClass = (pk: Value) =>
    markupProccess.setDefaultCategory(
      utils.getCategory(categories || [], Number(pk))
    )

  // сохранить изменения
  const onSaveMarkup = () => {
    const markupForSave = {
      entities: [
        {
          class: { class_id: 'ROOT' },
          selection: {},
          settings: {
            isVisible: true,
          },
          properties: Object.assign(
            {},
            ...Object.entries(toJS(markupProccess.rootSelection)).map(
              (ent) => ({ [ent[0]]: { value: ent[1] } })
            )
          ),
        },
        ...toJS(markupProccess.selections).map((sel) => ({
          class: {
            class_id: String(sel.category?.pk),
            value: sel.category?.name,
          },
          settings: {
            isVisible: true,
          },
          selection: sel.selection,
          properties: Object.assign(
            {},
            ...Object.entries(toJS(sel.properties)).map((ent) => ({
              [ent[0]]: {
                value: ent[1],
              },
            }))
          ),
        })),
      ],
    }
    axios
      .patch(
        `${DOMEN}/api/audio_markups/${markupId}/`,
        { data: markupForSave },
        { headers: token.headers }
      )
      .then((res) => setMarkup(res.data))
  }

  // получаем данные
  const getData = () => {
    const type = dataset?.config?.display_component?.data?.type
    const value = dataset?.config?.display_component?.data?.value
    switch (type) {
      case 'FILE_OBJECT': {
        return `${DOMEN}/media/${record.id}/`
      }
      case 'JSON_PATH': {
        return record.data[value] || ''
      }
      default:
        return ''
    }
  }

  // клик на кнопки редактировать, отменить изменения, сохранить
  const markupHandle = (value: string) => {
    switch (value) {
      case 'cancel':
        setIsEdit(false)
        setDefaultMarkup()
        break
      case 'edit':
        setIsEdit(true)
        break
      case 'save':
        onSaveMarkup()
        setIsEdit(false)
        break
      default:
        console.log('value')
        break
    }
  }

  // клик на кнопки отменить, отправить, отклонить, следующая на правой панели
  const controlHandle = (value: string) => {
    switch (value) {
      case 'reject':
        console.log('reject')
        break
      case 'next':
        console.log('next')
        break
      case 'cancel':
        setReason('')
        break
      case 'send':
        console.log('send')
        break
      default:
        console.log('value')
        break
    }
  }

  return markup && dataset && record ? (
    <Shared.Page>
      <Shared.PageTitleProject />
      <Mui.Paper sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        <Mui.Stack flex={1} gap={1} p={3} position={'relative'}>
          <Mui.Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Mui.Stack direction={'row'} gap={3}>
              <Mui.Typography>{userName}</Mui.Typography>
              <Mui.Typography color={'text.disabled'}>
                {roleName}
              </Mui.Typography>
            </Mui.Stack>
            <Mui.Stack
              direction={'row'}
              gap={1}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Mui.Typography>Время разметки</Mui.Typography>
              <TimeBox>{time}</TimeBox>
            </Mui.Stack>
          </Mui.Stack>
          <Mui.Divider />
          <Mui.Stack direction={'row'} justifyContent={'space-between'} />
          <ControlAsyncLoader
            isEdit={isEdit}
            data={getData()}
            onChange={(sel) => isEdit && onChange(sel)}
            onRemove={onRemove}
            datasetId={dataset.id}
            headers={token.headers}
            onChangeMagic={onChangeMagic}
            onActionClick={(value) => markupHandle(value)}
            component={dataset.config.display_component.type}
            defaultCategoryPk={markupProccess.defaultCategory?.pk}
            selections={markupProccess.selections.map((sel) => sel.selection)}
          />
        </Mui.Stack>
        <Mui.Divider orientation={'vertical'} />
        <Mui.Stack flexBasis={340} gap={2} mt={3} mb={2}>
          <Mui.Stack px={3} gap={1}>
            <CancelNextButtons onClick={(value) => controlHandle(value)} />
            <Mui.Divider />
          </Mui.Stack>
          {/* <Mui.Paper elevation={3}> */}
          {isEdit ? (
            <>
              <Mui.Stack px={3}>
                <Select
                  values={class_values}
                  onChange={onChangeDefaultClass}
                  value={markupProccess.defaultCategory?.pk || 0}
                />
              </Mui.Stack>
              {/* </Mui.Paper> */}
              <Mui.Divider />
              <Mui.Stack px={3} gap={1} overflow="scroll">
                <Mui.Paper elevation={1}>
                  {utils
                    .properties(dataset.config.markup_components, 'ROOT')
                    .map((elem) => {
                      let value
                      const entries = Object.entries(
                        markupProccess.rootSelection
                      )
                      const ent = entries.find((ent) => ent[0] === elem[0])
                      if (ent) value = ent[1]
                      return (
                        <Choositor
                          key={elem[1].label}
                          property={elem[1]}
                          categories={categories}
                          onChange={(value) =>
                            onChangeChoositor(elem[0], value)
                          }
                          value={value}
                        />
                      )
                    })}
                </Mui.Paper>
                <Mui.Stack gap={1}>
                  <Selector
                    categories={categories}
                    onChange={handler}
                    markups={markupProccess.selections}
                    markupComponents={dataset.config.markup_components}
                  />
                </Mui.Stack>
              </Mui.Stack>
            </>
          ) : (
            <ReasonsPanel
              value={action}
              reason={reason}
              values={actions}
              onChangeReason={(value) => setReason(value)}
              onChangeAction={(value) => setAction(value)}
              onControlActionClick={(value) => controlHandle(value)}
            />
          )}
        </Mui.Stack>
      </Mui.Paper>
    </Shared.Page>
  ) : (
    <Mui.Backdrop data-loader open={true}>
      <Mui.CircularProgress />
    </Mui.Backdrop>
  )
})
