import { createContext, useContext, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useFetch } from 'api'
import { FCC } from 'types'
import { PageState } from './state'
import { PropertyState } from './classes'
import { useInitialFetch, usePageReactions } from './hooks'

const PageContext = createContext({} as PageState)

export const usePage = () => useContext(PageContext)

export const PageProvider: FCC = observer((props) => {
  const { current } = useRef(new PageState())
  usePageReactions(current)
  useInitialFetch(current)
  useFetch(current.fetch)

  return <PageContext.Provider value={current} {...props} />
})

//  =====================================

export const useConfig = () => usePage().config

type Item = ReturnType<typeof useConfig>['item']

const ItemContext = createContext({} as Item)

export const useItem = () => useContext(ItemContext)

export const ItemProvider: FCC<{ value: Item }> = observer((props) => (
  <ItemContext.Provider {...props} />
))

//  =====================================

const PropertyContext = createContext({} as PropertyState)

export const useProperty = () => useContext(PropertyContext)

export const PropertyProvider: FCC = observer((props) => {
  const { property } = usePage()

  return property ? (
    <PropertyContext.Provider value={property} {...props} />
  ) : null
})
