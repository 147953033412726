import { observer } from 'mobx-react-lite'
import { Button, IconButton, Skeleton } from '@mui/material'
import { useCatalog } from 'features/catalogs'
import { Icon } from 'shared'
import { FCC } from 'types'

// =======================

export const NewCatalogButton = observer(() => {
  const catalog = useCatalog()

  return (
    <Button
      children={'Новый каталог'}
      variant={'contained'}
      sx={{ alignSelf: 'end' }}
      disabled={Boolean(catalog.fetch)}
      onClick={() => catalog.onToggleNewCatalog()}
    />
  )
})

// =======================

const ToggleButton: FCC = observer((props) => {
  const catalog = useCatalog()

  if (catalog.fetch) return <Skeleton variant={'button'} />

  if (!catalog.hasChild) return <></>

  return <IconButton onClick={() => catalog.onToggleOpen()} {...props} />
})

const ToggleIcon = observer(() => {
  const catalog = useCatalog()

  return (
    <Icon
      icon={'arrow'}
      sx={{
        transform: catalog.showList ? 'rotate(0)' : 'rotate(-90deg)',
      }}
    />
  )
})

export const Toggle = observer(() => (
  <ToggleButton>
    <ToggleIcon />
  </ToggleButton>
))

// =======================

const CategoryButton: FCC = observer((props) => {
  const catalog = useCatalog()

  if (catalog.fetch) return <Skeleton variant={'button'} />

  return <IconButton onClick={() => catalog.onToggleNewCatalog()} {...props} />
})

const CategoryIcon = observer(() => {
  const catalog = useCatalog()

  return (
    <Icon
      icon={'add'}
      sx={{
        transform: catalog.newCatalog ? 'rotate(45deg)' : 'rotate(0)',
      }}
    />
  )
})

export const Category = observer(() => (
  <CategoryButton>
    <CategoryIcon />
  </CategoryButton>
))

// =======================

const Done = observer(() => {
  const catalog = useCatalog()

  return (
    <IconButton
      type={'submit'}
      color={'primary'}
      disabled={!catalog.newCatalog?.name.trim()}
      children={<Icon icon={'done'} />}
      onClick={() => catalog.onCreate()}
    />
  )
})

const Remove = observer(() => {
  const catalog = useCatalog()

  return (
    <>
      <button type={'submit'} hidden onClick={() => catalog.onUpdate()} />
      <IconButton
        data-hidden
        children={<Icon icon={'remove'} />}
        onClick={() => catalog.onDelete()}
      />
    </>
  )
})

export const Action = observer(() => {
  const catalog = useCatalog()

  if (catalog.fetch) return <Skeleton variant={'button'} />

  return catalog.newCatalog ? <Done /> : <Remove />
})
