import { createContext, useContext, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useFetch } from 'api'
import { FCC } from 'types'
import { MarkupState } from './state'
import { ClassState } from './selections'
import { reaction } from 'mobx'

const MarkupContext = createContext({} as MarkupState)

export const useMarkup = () => useContext(MarkupContext)

const useGetTask = (markup: MarkupState) =>
  useEffect(() => markup.getTask(), [markup])

const useReactions = (makup: MarkupState) =>
  useEffect(() => {
    const { timer, selections: classes } = makup

    const disposers = [
      reaction(
        () => timer?.isOver,
        (isOver) => isOver && classes.resetItems()
      ),
      reaction(
        () => classes.items.length,
        (l) => l === 0 && classes.resetKey()
      ),
    ]

    return () => disposers.forEach((d) => d())
  }, [makup])

export const MarkupProvider: FCC = observer((props) => {
  const { current } = useRef(new MarkupState())
  useGetTask(current)
  useReactions(current)
  useFetch(current.fetch)

  return <MarkupContext.Provider value={current} {...props} />
})

export const useTimer = () => useMarkup().timer
export const useZoom = () => useMarkup().zoom
export const useClasses = () => useMarkup().selections
export const useMarkupText = () => useMarkup().markupText

const ClassContext = createContext({} as ClassState)

export const useClass = () => useContext(ClassContext)

export const ClassProvider: FCC<{ value: ClassState }> = observer((props) => (
  <ClassContext.Provider {...props} />
))
