import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material"

import { Icon } from "shared/icon"
import { projectType, projectTypes } from "app/constants"
import { getTypeColorHex, getTypeHumanName } from "./utils"


type RadioProjectProps = {
  type: keyof typeof projectType
  onChange: (type: keyof typeof projectType) => void
}

export const RadioProject = ({type, onChange}: RadioProjectProps) => {
  return (
    <ToggleButtonGroup exclusive value={type} onChange={(_, type) => type && onChange(type)}>
      {projectTypes.map(pt => (
        <Tooltip
          key={pt}
          title={getTypeHumanName(pt)}
          arrow
          disableInteractive
          sx={{
            color: getTypeColorHex(type === pt ? pt : '')
          }}
        >
          <ToggleButton value={pt}>
            <Icon icon={pt} />
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  )
}