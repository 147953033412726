import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material'

export type ActionSelectProps = {
  value: number
  values: {pk: number, name: string}[]
	onChangeAction: (value: number) => void
}

export const ActionSelect = ({ value, values, onChangeAction }: ActionSelectProps) => {
  return (
    <Box gap={1}>
      <Typography color={'text.secondary'}>Выберите действие</Typography>
      <FormControl fullWidth size="small">
        <Select
          onChange={(evt) => onChangeAction(Number(evt.target.value))}
          value={value || ''}
        >
          {values
            .sort((a, b) => ('' + a.name).localeCompare(b.name))
            .map((val) => (
              <MenuItem key={`${val.pk}`} value={`${val.pk}`}>
                {val.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  )
}
