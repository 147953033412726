import { observer } from 'mobx-react-lite'
import { FCC } from 'types'
import { Cells, GridRow as Row } from 'shared'
import { useCatalog } from 'features/catalogs'
import { parent } from './grid-cells'

export const GridRow: FCC<{ cells: typeof parent }> = observer((props) => {
  const catalog = useCatalog()

  return (
    <Row
      as={'form'}
      onSubmit={(e) => e.preventDefault()}
      data-selected={catalog.selected}
    >
      <Cells cells={props.cells} />
    </Row>
  )
})
