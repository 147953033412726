import { makeAutoObservable, observable } from 'mobx'
import { getDatasetId } from 'utils'
import { DatasetItem, Fetch, RecordItem, CatalogItem } from 'types'
import { ClassesState, PropertyState } from './classes'
import { ConfigState } from './config'

export class PageState {
  isLoading = true
  isPatching = false
  catalogs = observable.map<number, CatalogItem>()
  recordData?: RecordItem['data']

  dataset?: DatasetItem

  fetch?: Fetch | null
  record?: RecordItem
  errorMsg?: null | string
  property?: null | PropertyState
  classes = new ClassesState()
  config = new ConfigState()

  constructor() {
    makeAutoObservable(this)
  }

  get name() {
    return this.dataset?.name ?? '...'
  }

  get recordKyes() {
    return Object.keys(this.recordData ?? {})
  }

  toggleNewProp = () => {
    this.property = this.property ? null : new PropertyState()
  }

  patch = () => {
    this.fetch = {
      url: `/api/audio_dataset/${getDatasetId()}/state/configure/`,
      method: 'PATCH',
      data: { config: this.config.object },
      onResponse: () => (this.fetch = null),
      onSuccess: (res) => {
        this.config.setConfig(res.body.response.config)
        this.errorMsg = res.body.validation_err_msg
      },
    }
  }
}
