import * as Mobx from 'mobx-react-lite'
import * as Mui from '@mui/material'
import * as RRD from 'react-router-dom'
import * as Shared from 'shared'

const Nav = Mui.styled('nav')(({ theme }) => ({
  '--active-color': theme.palette.primary.main,
  alignSelf: 'stretch',
  display: 'grid',
  gridAutoRows: 40,
}))

const links: Shared.IconKey[] = [
  'datasets',
  'users',
  'catalogs',
]

const Links = Mobx.observer<{ path?: string }>(({ path }) => {
  const { pathname } = RRD.useLocation()
  return (
    <>
      {links
        .slice(Number(!path))
        .filter(l => pathname !== '/' || (l !== 'catalogs' && l !== 'users'))
        .map((l) => (
        <Shared.IconLink key={l} to={[path, l].join('/')} icon={l} />
      ))}
    </>
  )
})

const Home = Mobx.observer(() => <Shared.IconLink to={'/'} icon={'home'} />)

const usePath = () => RRD.useMatch('project/:id/*')?.pathnameBase

export const Navigation = Mobx.observer(() => (
  <Nav>
    <Home />
    <Links path={usePath()} />
  </Nav>
))
