import { FormEvent } from 'react'
import { observer } from 'mobx-react-lite'
import * as Mui from '@mui/material'
import { FormLoginProvider, useFormLogin } from 'features/form-login'

const Username = observer(() => (
  <Mui.TextField {...useFormLogin().username.props} autoFocus />
))

const Password = observer(() => (
  <Mui.TextField {...useFormLogin().password.props} type="password" />
))

const Submit = observer(() => (
  <Mui.Button
    children={'Вход'}
    variant={'contained'}
    type={'submit'}
    disabled={useFormLogin().isDisabled}
    onClick={useFormLogin().submit}
  />
))

const Loader = observer(() => (
  <Mui.Backdrop data-loader open={useFormLogin().isLoading}>
    <Mui.CircularProgress />
  </Mui.Backdrop>
))

export const FormLogin = () => (
  <Mui.Box
    component="form"
    alignSelf={'start'}
    display={'grid'}
    gap={2}
    gridTemplateColumns={'1fr 1fr'}
    position={'relative'}
    sx={{ div: { gridColumn: '1 / -1' } }}
    onSubmit={(e: FormEvent) => e.preventDefault()}
  >
    <Username />
    <Password />
    <Mui.Button>Забыли пароль?</Mui.Button>
    <Submit />
    <Loader />
  </Mui.Box>
)

export const Login = () => (
  <Mui.Box
    height={'100vh'}
    display={'grid'}
    alignItems={'center'}
    justifyItems={'center'}
  >
    <Mui.Typography variant={'login'}>Marker</Mui.Typography>
    <FormLoginProvider>
      <FormLogin />
    </FormLoginProvider>
  </Mui.Box>
)
