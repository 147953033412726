import { intervalToDuration } from 'date-fns'

export const getString = (number: number = 0) => {
  if (number) {
    return number < 10 ? `0${number}` : `${number}`
  } else {
    return '00'
  }
}
export const getTime = (task: any) => {
  const interval = intervalToDuration({
    start: new Date(task.issued_at),
    end: new Date(task.completed_at),
  })
  const { hours, minutes, seconds } = interval
  return `${getString(hours || 0)}:${getString(minutes || 0)}:${getString(
    seconds || 0
  )}`
}
