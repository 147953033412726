import { Texture } from 'pixi.js'
import { Sound, utils } from '@pixi/sound'
import { useEffect, useState } from 'react'
import { Sprite, Stage } from '@inlet/react-pixi'
import { Box, IconButton, Typography } from '@mui/material'
import { useReactMediaRecorder } from 'react-media-recorder'
import { Mic, PlayArrow, GraphicEq, Pause } from '@mui/icons-material'

import { Pointer } from './pointer'


export const Record = ({ label, value, onChange }: InputProps) => {
  const [currentTime, setCurrentTime] = useState(0)
  const [sound, setSound] = useState<Sound>()
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true })
  const [texture, setTexture] = useState<Texture>(Texture.EMPTY)
  
  const recording = status === 'recording'

  useEffect(() => {
    return () => {
      if (sound?.isPlaying) {
        sound?.pause()
      }
    }
  }, [sound])

  useEffect(() => {
    if (value) {
      let url = value
      try { url = JSON.parse(value) } catch {}
      Sound.from({
        url,
        preload: true,
        singleInstance: true,
        loaded: (err, inst) => {
          if (inst) {
            const baseTexture = utils.render(inst, {
              width: 100,
              height: 40,
              fill: '#6BA2F4',
            })
            setTexture(new Texture(baseTexture))
            setSound(inst)
          }
        },
      })
    }
    return () => {
      setCurrentTime(0)
      setSound(undefined)
    }
  }, [value])

  useEffect(() => {
    if (mediaBlobUrl) {
      fetch(mediaBlobUrl)
        .then((r) => r.blob())
        .then((blob) => {
          let fileReader = new FileReader()
          fileReader.readAsDataURL(blob)
          fileReader.onload = () => {
            onChange && onChange(JSON.stringify(fileReader.result))
          }
        })
      }
  }, [mediaBlobUrl, onChange])

  const recordToggle = () => (recording ? stopRecording() : startRecording())

  const playToggle = () => {
    if (sound?.isPlaying) {
      sound?.pause()
    } else {
      const s: any = sound?.play()
      s?.on('progress', (time: number) => setCurrentTime(time))
    }
  }

  return (
    <Box>
      <Typography>{label}</Typography>
      <Box display="flex" flexDirection="row">
        <IconButton onClick={recordToggle}>
          {recording ? <GraphicEq /> : <Mic />}
        </IconButton>
        <IconButton sx={{ ml: 2 }} onClick={playToggle} disabled={!sound}>
          {sound?.isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
        <Stage
            width={100}
            height={40}
            options={{ backgroundColor: 0xffffff }}
          >
          <Sprite texture={texture} />
          <Pointer
              height={200}
              progress={currentTime}
              width={100}
          />
        </Stage>
      </Box>
    </Box>
  )
}
