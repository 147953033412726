import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import { Root, Switch } from './styled'

const toggleState = {
  active: 0,

  onToggle() {
    this.active ? this.active-- : this.active++
  },

  get inputPorps(): JSX.IntrinsicElements['input'] {
    return {
      type: 'checkbox',
      checked: !!this.active,
      onChange: this.onToggle,
    }
  },
}

export const useToggle = () => useLocalObservable(() => toggleState)

interface ToggleProps {
  toggle: typeof toggleState
  labels: string[]
  disabled?: boolean
}

export const Toggle = observer<ToggleProps>(({ toggle, labels, disabled }) => (
  <Root
    data-disabled={disabled}
    onKeyDown={({ key }) => {
      if (key === 'Enter') toggle.onToggle()
    }}>
    {labels[0]}
    <Switch data-disabled={disabled}>
      <Observer>
        {() => <input {...toggle.inputPorps} disabled={disabled} />}
      </Observer>
      <span />
    </Switch>
    {labels[1]}
  </Root>
))
