import { makeAutoObservable } from 'mobx'
import { Fetch, ProjectItem, ResponseProject } from 'types'
import { getProjectId } from 'utils'
import { projects, newProject } from './store'

type Data = Partial<ProjectItem>
type View = 'edit' | 'remove' | null

export class Project {
  constructor(
    public data: Data = {},
    public view?: View,
    public fetch?: Fetch | null,
    private _curr = data
  ) {
    !data.id && this.setView('edit')

    makeAutoObservable(this)
  }

  setView(view: View) {
    if (!view) {
      this.data.name = this._curr?.name
      this.data.description = this._curr?.description
    }
    this.view = view
  }

  onChange(data: Data) {
    Object.assign(this.data, data)
  }

  get id() {
    return this.data.id
  }

  get name(): string {
    return this.data.name ?? ''
  }

  get description(): string {
    return this.data.description ?? ''
  }

  get type() {
    return this.data.type
  }

  get is_template() {
    return this.data.is_template
  }

  get isDisabled() {
    return [
      !this.name.trim(),
      this.name === this._curr?.name &&
        this.description === this._curr.description,
    ].some(Boolean)
  }

  getProject() {
    this.fetch = {
      method: 'GET',
      url: `/api/projects/${getProjectId()}/`,
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseProject) => {
        this._curr = res.body
        this.data = res.body
      },
    }
  }

  onCreate() {
    this.fetch = {
      method: 'POST',
      url: '/api/projects/',
      data: this.data,
      onResponse: () => newProject.set(false),
      onSuccess: (res: ResponseProject) => {
        projects.unshift(new Project(res.body))
      },
    }
  }

  createProjectFromTemplate(project: Project) {
    const data = new FormData()
    data.append('template_project_id', String(project.id))
    data.append('name', this.name)
    data.append('description', this.description)

    this.fetch = {
      data,
      method: 'POST',
      url: '/api/projects/create_from_template_project/',
      onResponse: () => newProject.set(false),
      onSuccess: (res: any) => projects.unshift(new Project(res.body)),
      onError: e => console.log('error -> ', e),
    }
  }

  onUpdate() {
    this.fetch = {
      method: 'PATCH',
      url: `/api/projects/${this.id}/`,
      data: this.data,
      onResponse: () => {
        this.fetch = null
        this.view = null
      },
      onSuccess: (res: ResponseProject) => {
        this._curr = res.body
        this.data = res.body
      },
    }
  }

  onDelete() {
    this.fetch = {
      method: 'DELETE',
      url: `/api/projects/${this.id}/`,
      onSuccess: () => projects.remove(this),
    }
  }
}
