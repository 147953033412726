import { makeAutoObservable } from 'mobx'
import { Fetch, ResponseProjects } from 'types'
import { projects, newProject } from './store'
import { Project } from './project'

export class Page {
  constructor(public fetch?: Fetch | null, public list = projects, public users = []) {
    this.getList()
    makeAutoObservable(this)
  }

  getList() {
    this.fetch = {
      method: 'GET',
      url: '/api/projects/',
      query: { page_size: 100000 },
      onResponse: () => (this.fetch = null),
      onSuccess: (res: ResponseProjects) => {
        projects.replace(res.body.results.map((p) => new Project(p)))
      },
    }
  }

  get showNewProject() {
    return newProject.get()
  }

  onToggleNewProject() {
    newProject.set(!this.showNewProject)
  }
}
