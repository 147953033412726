import { createContext, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { FCC } from 'types'
import { TimerState } from './state'
import { useInterval } from 'hooks'

const TimerContext = createContext({} as TimerState)

export const useTimer = () => useContext(TimerContext)

export const TimerProvider: FCC<{ value: TimerState }> = observer((props) => {
  useInterval(() => props.value.tick())

  return <TimerContext.Provider {...props} />
})
