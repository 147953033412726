export const markerFromat = '.mnf'

export const textFormats = ['.xls', '.xlsx', '.csv', '.json', '.parquet']
export const audioFormats = ['.mp3', '.wav', '.m4a', '.webm', '.ogg', '.ogv', '.flac']
export const imageFormats = ['.jpg', '.png', '.jpeg', '.png', '.bmp']
export const videoFormats = ['.ogv', '.mp4', '.avi']

export enum projectType {
  TEXT = "TEXT",
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export const projectTypes = [
  projectType.TEXT,
  projectType.AUDIO,
  projectType.IMAGE,
  projectType.VIDEO,
]
