import CircularProgress from '@mui/material/CircularProgress'
import { Backdrop } from '@mui/material'


export type LoaderProps = {
  isLoader: boolean
}
export const Loader = ({ isLoader }: LoaderProps) => {
  return (
    <Backdrop data-loader open={isLoader}>
      <CircularProgress />
    </Backdrop>
  )
}
