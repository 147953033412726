import { makeAutoObservable } from 'mobx'

export class ZoomState {
  private current: number

  constructor(public size = 16, public step = 4) {
    this.current = size

    makeAutoObservable(this)
  }

  onIncrement = () => {
    this.size = this.size + this.step
  }

  onDecrement = () => {
    this.size = this.size - this.step
  }

  get disableInc() {
    return this.size >= this.current + this.step * 5
  }

  get disabledDec() {
    return this.size <= this.current
  }
}
