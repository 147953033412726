import { TextField } from '@mui/material'
import { NumericFormat } from 'react-number-format'


type FieldRatingProps = {
  value: number
  disabled?: boolean
  onChange: (value: number) => void
}

const style = {
  width: '100px',
  '& input': {
    textAlign: 'center',
  },
}

export const UserRating = ({ value, onChange, disabled }: FieldRatingProps) => {
  return (
    <NumericFormat
      sx={style}
      suffix={'%'}
      decimalScale={0}
      value={value * 100}
      disabled={disabled}
      valueIsNumericString
      allowNegative={false}
      customInput={TextField}
			inputProps={{ readOnly: true }}
      isAllowed={(values) => Number(values.value) <= 100}
      onValueChange={({ floatValue = 0 }) => onChange(floatValue / 100)}
    />
  )
}
