import { Order, Markup } from './type'


const getValue = (value: any) => {
  return typeof value === 'undefined' ? 0 : value
}

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (getValue(b[orderBy]) < getValue(a[orderBy])) {
    return -1
  }
  if (getValue(b[orderBy]) > getValue(a[orderBy])) {
    return 1
  }
  return 0
}

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export const getValuesForRender = (markups: Markup[], key: string) => {
  return markups
    .map((markup: any) => markup[key])
    .filter((el, ind, arr) => ind === arr.lastIndexOf(el))
}

export const getFilterValues = (filter: any) => {
  const newFilter = { ...filter }
  Object.keys(filter).forEach((key) => {
    if (filter[key] === '') delete newFilter[key]
  })
  return newFilter
}

export const getDisabled = (filter: any) => {
  return !Object.values(filter).some((value) => value !== '')
}

export const getFiltered = (filterValues: any, markups: Markup[]) => {
  return markups.filter((markup: any) =>
    Object.keys(filterValues).every((key) => markup[key] === filterValues[key])
  )
}
