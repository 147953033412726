import * as Mui from '@mui/material'

export const Page = Mui.styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  overflow: 'auto',
}))
