import { styled } from '@mui/material'

export const Root = styled('label')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  placeItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(1.5, 3),
  cursor: 'pointer',

  '&[data-disabled="true"]': {
    cursor: 'unset',
  },
}))

export const Switch = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 28,
  height: 6,
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.primary.light,
  position: 'relative',

  '&[data-disabled="true"]': {
    background: theme.palette.grey[300],
  },

  span: {
    display: 'block',
    width: 14,
    height: 14,
    right: 'calc(100% - 14px)',
    borderRadius: '50%',
    background: theme.palette.primary.main,
    transition: theme.transitions.create('transform'),
  },

  input: {
    opacity: 0,
    position: 'absolute',
    zIndex: -1,

    ':where(:hover,:focus,:action):not(:disabled) + span': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },

    ':checked + span': {
      transform: 'translateX(100%)',
    },

    ':disabled + span': {
      background: theme.palette.grey[400],
    },
  },
}))
