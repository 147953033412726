import { observer } from 'mobx-react-lite'
import * as Mui from '@mui/material'

import { useMarkup } from 'features/page-markup'
import markupProccess from './showers/state'
import { toJS } from 'mobx'


const Template = Mui.styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(1),
}))

const Skeleton = observer(() => (
  <Template>
    <Mui.Skeleton variant={'button'} />
    <Mui.Skeleton variant={'button'} />
  </Template>
))

const ButtonSkip = observer(() => {
  const { onSkip } = useMarkup()
  return (
    <Mui.Button
      children={'Пропустить'}
      onClick={() => {
        onSkip()
        markupProccess.clear()
      }}
    />
  )
})

const ButtonConfirm = observer(() => {
  const { onConfirm } = useMarkup()

  return (
    <Mui.Button
      children={'Подтвердить'}
      variant={'contained'}
      onClick={() => {
        onConfirm([
          {
            class: { "class_id": "ROOT" },
            selection: {},
            settings: {
              isVisible: true
            },
            properties: Object.assign({}, ...Object.entries(toJS(markupProccess.rootSelection)).map(ent => ({[ent[0]]: {value: ent[1]}}))),
          },
          ...toJS(markupProccess.selections).map(sel => ({
            class: {
              class_id: String(sel.category?.pk || ''),  // need to check it out
              value: sel.category?.name,
            },
            settings: {
              isVisible: true
            },
            selection: sel.selection,
            properties: Object.assign(
              {},
              ...Object.entries(toJS(sel.properties)).map(ent => ({
                [ent[0]]: {
                  value: ent[1],
                },
              }))),
          }))
        ])
        markupProccess.clear()
      }}
    />
  )
})

export const Actions = observer(() => {
  const markup = useMarkup()

  if (markup.isDisabled) return <Skeleton />

  return (
    <Template>
      <ButtonSkip />
      <ButtonConfirm />
    </Template>
  )
})
