import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Stack,
  Typography,
  Button,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Dialog,
  DialogTitle,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from '@mui/material'

import { NumbersState } from 'features/numbers'
import { Project } from 'features/projects/project'
import { RadioProject } from 'shared/ui/radio-project'
import { useProject, usePage } from 'features/projects'
import { Icon, IconLink, IconLinkProps, Numbers } from 'shared'

import { Field } from './field'
import { ButtonClose, ButtonEdit, ButtonRemove, ButtonSubmit } from './buttons'


const links: IconLinkProps[] = [
  { to: 'datasets', icon: 'datasets' },
  { to: 'users', icon: 'user' },
  { to: 'catalogs', icon: 'catalogs' },
]

interface TemplateProjectsProps {
  open: boolean
  onClose: () => void
  projects: Project[]
}

const TemplateProjects = observer((props: TemplateProjectsProps) => {
  const { onClose, open, projects } = props

  const projectCase = useProject()

  const handleListItemClick = (project: Project) => {
    onClose()
    projectCase.createProjectFromTemplate(project)
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      scroll="paper"
      maxWidth="md"
    >
      <DialogTitle>Выберите шаблонный проект</DialogTitle>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Название</TableCell>
            <TableCell align="center">Описание</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects
            .sort((a, b) => (a.id || 0) - (b.id || 0))
            .map((project) => (
              <TableRow
                key={project.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={() => handleListItemClick(project)}
              >
                <TableCell align="center" component="th" scope="row">
                  {project.name}
                </TableCell>
                <TableCell align="center">{project.description}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Dialog>
  )
})

export const Main = observer(() => {
  const project = useProject()

  return !project.view ? (
    <>
      <Stack direction={'row'}>
        <Button
          tabIndex={-1}
          component={'span'}
          data-type={project.type}
          children={<Icon icon={project.type} />}
        />
        <ButtonEdit />
      </Stack>
      <Stack flex={1} my={1}>
        <Typography 
          children={project.name} 
          variant={'subtitle1'} 
          sx={{
            lineHeight: '20px',
            marginBottom: 0.5,
            marginTop: 0.5,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word'
          }}
        />
        <Typography 
          children={project.description}
          variant={'body2'}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          }}
        />
      </Stack>
      <Stack direction={'row'} gap={1}>
        {links.map((link) => (
          <IconLink
            key={link.icon}
            icon={link.icon}
            to={`/project/${project.id}/${link.to}`}
          />
        ))}
      </Stack>
    </>
  ) : null
})

export const Edit = observer(() => {
  const project = useProject()
  const page = usePage()

  const [openTmpl, setOpenTmpl] = useState(false)

  return project.view === 'edit' ? (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack gap={2} flex={1} mb={1}>
        <Field field={'name'} />
        <Field field={'description'} />
        <Box display="flex" alignSelf="center">
          {page.showNewProject && (
            <>
              <RadioProject
                type={project.type || 'TEXT'}
                onChange={(type) => project.onChange({ type })}
              />
              <ToggleButtonGroup
                exclusive
                value={'SOURCE'}
                onClick={() => project.name.trim() && setOpenTmpl(true)}
              >
                <Tooltip
                  title={
                    project.name.trim()
                      ? 'Выбрать шаблон'
                      : 'Введите название проекта'
                  }
                  // disableInteractive
                  arrow
                >
                  <Box>
                    <ToggleButton
                      sx={{ ml: 1, color: '#00897b' }}
                      value="SOURCE"
                      disabled={!project.name.trim()}
                    >
                      <Icon icon={'SOURCE'} />
                    </ToggleButton>
                  </Box>
                </Tooltip>
              </ToggleButtonGroup>
            </>
          )}
        </Box>
      </Stack>
      <Stack direction={'row'} gap={1}>
        <ButtonRemove />
        <ButtonClose />
        <ButtonSubmit />
      </Stack>
      <TemplateProjects
        open={openTmpl}
        onClose={() => setOpenTmpl(false)}
        projects={page.list.filter((p) => p.is_template)}
      />
    </form>
  ) : null
})

export const Remove = observer(() => {
  const project = useProject()
  const numbers = useRef(new NumbersState(2)).current

  useEffect(() => {
    project.view === 'remove' && numbers.update()
  }, [numbers, project.view])

  useEffect(() => {
    numbers.valid && project.onDelete()
  }, [numbers.valid, project])

  return project.view === 'remove' ? (
    <>
      <Typography
        children={`Введите число ${numbers.removeStr}`}
        variant={'subtitle1'}
        textAlign={'center'}
      />

      <Numbers numbers={numbers} />
      <ButtonClose />
    </>
  ) : null
})
