import { createContext, useContext, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useFetch } from 'api'
import { FCC } from 'types'
import { Catalog } from './catalog'
import { useTimeout } from 'hooks'

// =============== page catalogs

const CatalogContext = createContext({} as Catalog)

export const useCatalog = () => useContext(CatalogContext)

export const PageProvider: FCC = observer((props) => {
  const page = useRef(new Catalog()).current
  useEffect(() => page.getList({ level: 0 }), [page])
  useFetch(page.fetch)

  return <CatalogContext.Provider value={page} {...props} />
})

// ===============  catalog

export const CatalogProvider: FCC<{ value: Catalog }> = observer((props) => {
  useFetch(props.value.fetch)
  useReactions(props.value)

  return <CatalogContext.Provider {...props} />
})

const useReactions = (ct: Catalog) =>
  useTimeout(
    () => {
      if (
        ct.fetch ||
        ct.newCatalog ||
        !ct.name ||
        (ct.name === ct.cur.name &&
          ct.color === ct.cur.color &&
          ct.description === ct.cur.description)
      )
        return

      ct.onUpdate()
    },
    700,
    [ct.color, ct.name, ct.description]
  )
