import React from 'react'
import { IMaskInput } from 'react-imask'


export type TextMaskTimerProps = {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const TextMaskTimer = React.forwardRef<HTMLElement, TextMaskTimerProps>(
  function TextMaskTimer(props) {
    const { onChange, name, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="##:1#:1#"
        lazy={false}
        placeholderChar="0"
        definitions={{
          '#': /[0-9]/,
          '1': /[0-5]/,
        }}
        onAccept={(value: any) => onChange({ target: { name, value } })}
        // overwrite
      />
    )
  }
)
