import {
  Arrow,
  Input,
  Container,
  IndexButton,
} from "../atoms"


type CompositeInputProps = ArrowProps & IndexButtonProps & InputProps

export const CompositeInput = ({
  label, onChange, value,
  index, color, onRemove, focus,
}: CompositeInputProps) => (
  <Container>
    <Arrow focus={focus} />
    <IndexButton {...{color, index, onRemove}} />
    <Input {...{label, onChange, value}} />
  </Container>
)
