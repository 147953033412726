import { Box } from '@mui/material'
import { Select, Checkbox, Input, Record } from "../atoms"
import * as u from "../utils/parsers"


type Props = {
  property: Property
  categories: Category[]
  onChange: OnChange
  value?: Value
}

export const Choositor = ({ property, categories, onChange, value }: Props) => {
  const { label, type } = property
  const values = u.categoriesFromParent(categories, property.settings?.values.catalog_id)

  const choose = () => {
    switch(type) {
      case 'CHECK_BOX': return <Checkbox {...{label, onChange, value}} />
      case 'INPUT_BOX_TEXT': return <Input {...{label, onChange, value}} />
      case 'COMBO_BOX': return <Select {...{label, values, onChange, value}} />
      case 'AUDIO_RECORD': return <Record {...{label, values, onChange, value}} />
      default: return <></>
    }
  }

  return <Box m={1}>{choose()}</Box>
}