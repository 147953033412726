import { computed, IComputedValue, values, entries } from 'mobx'
import { MenuItemProps } from '@mui/material'
import { Config, MarkupComponents } from 'types'
import {
  catalogId,
  catalogs,
  category,
  dataType,
  dataValue,
  dataset,
  enableSelection,
  record,
  classes,
  jsonView,
  validationError,
} from './store'

type ComputedMenuItems = IComputedValue<MenuItemProps[]>

export const disabledNewProp = computed(
  () => !category.get() && !enableSelection.get()
)

export const propsList = computed(() =>
  entries(classes).flatMap(([, propMap]) =>
    entries(propMap).map(([, prop]) => prop)
  )
)

export const currentPropsList = computed(() =>
  propsList
    .get()
    .filter((p) => (category.get() ? p : !p.isRoot))
    .filter((p) => (enableSelection.get() ? p : p.isRoot))
    .sort((a, b) => String(b.classKey).localeCompare(a.classKey))
)

export const currentOrder = computed(() =>
  currentPropsList.get().reduce((num, item) => Math.max(num, item.order), -1)
)

const currentClasses = computed(() =>
  currentPropsList.get().reduce((obj, item) => {
    const { classKey, propKey } = item
    obj[classKey] = {
      properties: {
        ...(obj[classKey]?.properties ?? {}),
        [propKey]: item.property,
      },
    }

    return obj
  }, {} as MarkupComponents['classes'])
)

export const configObject = computed(() => {
  const { config } = dataset.get() ?? {}
  const id = catalogId.get()

  return {
    meta: config?.meta,
    display_component: {
      type: config?.display_component.type,
      data: {
        type: dataType.get(),
        value: dataValue.get(),
      },
      enable_entity_selection: enableSelection.get(),
    },
    markup_components: {
      classes: currentClasses.get(),
      class_values: enableSelection.get()
        ? { catalog_id: id ? Number(id) : undefined }
        : undefined,
    },
  } as Config
})

export const dataValueItems = computed(() =>
  Array.from(Object.keys(record.get()?.data ?? {}))
    .concat('file')
    .sort()
)

export const rootCatalogItems: ComputedMenuItems = computed(() =>
  values(catalogs)
    .filter((c) => !c.parent)
    .map<MenuItemProps>((c) => ({
      value: c.id,
      children: c.name,
      color: c.color,
    }))
)

const childCatalogItems: ComputedMenuItems = computed(() => {
  const id = catalogId.get()

  return id
    ? values(catalogs)
        .filter((c) => c.parent?.includes(id))
        .map<MenuItemProps>((c) => ({
          value: c.id,
          children: c.name,
          color: c.color,
        }))
    : []
})

export const propertyClassItems: ComputedMenuItems = computed(() =>
  [
    category.get() ? { value: 'ROOT', children: 'Весь объект' } : {},
    enableSelection.get() ? { value: 'COMMON', children: 'Сущность (выделенная часть)' } : {},
    ...childCatalogItems.get(),
  ]
    .filter((i) => i.value)
    .filter((i) => (enableSelection.get() ? i : i.value === 'ROOT'))
)

export const json = computed(() => {
  const view = jsonView.get()

  if (view === 'settings') return JSON.stringify(configObject.get(), null, 2)
  if (view === 'data') return JSON.stringify(record.get()?.data ?? {}, null, 2)
  if (view === 'error') return validationError.get()

  return ''
})
