import { InputHTMLAttributes } from 'react'
import { action, makeAutoObservable, observable } from 'mobx'

type I = HTMLIFrameElement
type N = InputHTMLAttributes<HTMLInputElement> & { key: string }

export class NumbersState {
  array = observable.array<N>()
  removeStr = ''

  constructor(private size = 0) {
    this.getRandom()
    new Array(size).fill('').forEach(this.createNum)

    makeAutoObservable(this)
  }

  private getRandom = () => {
    this.removeStr = String(Math.random()).slice(2, 2 + this.size)
  }

  private createNum = (value: string, i: number) => {
    this.array.push({
      value,
      key: `num-${i}`,
      maxLength: 1,
      autoFocus: i === 0,

      onChange: action(({ target }) => {
        const { value, nextSibling } = target

        if (Number.isInteger(+value)) {
          this.array[i].value = target.value
          ;(nextSibling as I | null)?.focus()
        }
      }),

      onKeyDown: action(({ key, currentTarget }) => {
        if (key === 'Backspace') {
          const { children } = currentTarget.parentElement as HTMLElement
          const [input] = children as unknown as I[]

          input.focus()
          this.array.forEach((n) => (n.value = ''))
        }
      }),
    })
  }

  update = () => {
    this.getRandom()
    this.array.forEach((n) => (n.value = ''))
  }

  get valid() {
    return this.array.map((n) => n.value).join('') === this.removeStr
  }
}
