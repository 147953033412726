import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import { renderMenuItems } from 'utils'
import { useConfig, useItem } from 'features/page-datasets-settings'

type T = Property['type']

export const Class = observer(() => {
  const config = useConfig()
  const item = useItem()

  return (
    <TextField
      label={'Класс объекта'}
      select
      value={item.classKey}
      children={renderMenuItems(config.classKeys)}
      onChange={action(({ target }) => (item.classKey = target.value))}
    />
  )
})

export const Label = observer(() => {
  const item = useItem()

  return (
    <TextField
      label={'Наименование'}
      value={item.label}
      onChange={action(({ target }) => (item.label = target.value))}
    />
  )
})

export const Type = observer(() => {
  const item = useItem()

  return (
    <TextField
      label={'Класс объекта'}
      select
      value={item.type}
      onChange={action(({ target }) => (item.type = target.value as T))}
      children={renderMenuItems([
        'CHECK_BOX',
        'COMBO_BOX',
        'INPUT_BOX_TEXT',
      ] as T[])}
    />
  )
})

export const Data = observer(() => {
  const item = useItem()

  return (
    <TextField
      label={'Поле данных'}
      value={item.propKey}
      onChange={action(({ target }) => {
        item.propKey = target.value
      })}
    />
  )
})

export const Value = observer(() => {
  const item = useItem()

  if (item.type !== 'COMBO_BOX') return null

  return (
    <TextField
      label={'Источник значений'}
      select
      value={item.catalogId || ''}
      onChange={action(({ target }) => (item.catalogId = +target.value))}
      children={renderMenuItems([item.catalogId || ''])}
    />
  )
})
