import { useEffect } from 'react'
import { getCategories, getDataset, getRecords, patchConfig, patchDatasetSettings } from './fetch'

import { stopLoading, clear } from './action'
import { patch } from './store'

export function useGetData() {
  useEffect(() => {
    const requests = [getDataset(), getCategories(), getRecords()]

    Promise.all(requests).then(stopLoading)

    return () => {
      requests.forEach((r) => r.abort())
      clear()
    }
  }, [])
}

export function usePatch() {
  const start = patch.get()

  useEffect(() => {
    if (!start) return

    const patch = patchConfig()
    // Отправка дополнительных настроек датасета
    const patchSettings = patchDatasetSettings()
    
    patch.then(() => patchSettings.then())

    return () => patch.abort()
  }, [start])
}
