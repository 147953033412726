import { useEffect, DependencyList as Dep } from 'react'

export function useTimeout(cb: () => void, delay = 1000, dep?: Dep) {
  useEffect(() => {
    const timer = setTimeout(cb, delay)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep)
}

export function useInterval(cb: () => void, delay = 1000, dep?: Dep) {
  useEffect(() => {
    const timer = setInterval(cb, delay)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep)
}
