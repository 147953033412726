import { observer } from 'mobx-react-lite'
import { Typography, Stack } from '@mui/material'
import { useProject } from 'features/projects'
import { useDataset } from 'features/datasets'
import { Icon } from '../icon'

export const PageTitleProject = observer(() => {
  const project = useProject()

  return <Typography variant={'h1'} children={project.name || '...'} />
})

export const PageTitleDataset = observer(() => {
  const project = useProject()
  const dataset = useDataset()
  return (
    <Typography variant={'h1'} display={'flex'} alignItems={'center'} gap={1}>
      <Stack display={'contents'} color={'text.disabled'}>
        {project.name || '...'}
        <Icon icon={'arrow'} sx={{ transform: 'rotate(-90deg)' }} />
      </Stack>
      {dataset.name || '...'}
    </Typography>
  )
})
