import { observer } from 'mobx-react-lite'
import { GridRow, Cells } from 'shared'
import { DatasetProvider, useDataset, usePage } from 'features/datasets'
import { rowCells, skeletons } from './grid-cells'

const SkeletonRow = observer(() => (
  <GridRow>
    <Cells cells={skeletons} />
  </GridRow>
))

const Row = observer(() => {
  const ds = useDataset()

  if (ds.fetch) return <SkeletonRow />

  return (
    <GridRow>
      <Cells cells={rowCells} />
    </GridRow>
  )
})

const Loader = observer(() => {
  const page = usePage()

  return page.fetch ? (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  ) : null
})

export const GridBody = observer(() => {
  const page = usePage()

  return (
    <>
      {page.list.map((d) => (
        <DatasetProvider key={d.id} value={d}>
          <Row />
        </DatasetProvider>
      ))}
      <Loader />
    </>
  )
})
