import { observer } from 'mobx-react-lite'
import { Paper, Skeleton, Stack } from '@mui/material'

import { useMarkup } from 'features/page-markup'
import { Choositor, utils } from 'markers/selectors'

import markupProccess from './showers/state'


export const RootSelection = observer(() => {
  const { categories, markupComponents, isDisabled } = useMarkup()

  const onChange = (propertyKey: string, value: Value) => {
    markupProccess.setRootSelection(propertyKey, value)
  }

  if (isDisabled) {
    return (
      <Stack gap={2}>
        <Skeleton variant={'button'} />
        <Skeleton variant={'button'} />
      </Stack>
    )
  }

  return (
    <Paper elevation={1}>
      {utils.properties(markupComponents, 'ROOT').map(elem => {
        // жалкая попытка победить плохой тип
        let value
        const entries = Object.entries(markupProccess.rootSelection)
        const ent = entries.find(ent => ent[0] === elem[0])
        if (ent) value = ent[1]
        return (
          <Choositor
            key={elem[1].label}
            property={elem[1]}
            categories={categories}
            onChange={(value) => onChange(elem[0], value)}
            value={value}
          />
        )
      })}
    </Paper>
  )
})
