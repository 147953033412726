export const queries = {
  tokenAuth: `
    mutation TokenAuth($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        token
      }
    }`,

  tackByProject: `
    query TaskByProject($id: Int!) {
      taskByProject(projectId: $id) {
        id
        expiredAt
        issuedAt
        data {
          edges {
            node {
              id
              data
              preliminaryMarkupData
            }
          }
        }
        dataset {
          config
					id
        }
      }
    }
  `,

  createMarkup: `
    mutation CreateMarkup($markups: [MarkupInput!], $task: String!) {
      createMarkup(markups: $markups, task: $task) {
        ok
        errors
      }
    }
  `,

  categoriesSubtree: `
    query categoriesSubtree($id: Int!) { 
      categoriesSubtree (pk: $id) {
        edges {
          node {
            pk
            name
            color
            examples
            description
          }
        }
      }
    }
  `,

  categoriesOnProject: `
    query categoriesSubtree($id: Int!) {
      allCategories (projectId: $id) {
        edges {
          node {
            pk
            name
            color
            examples
            description
            parent {
              pk
            }
          }
        }
      }
    }
  `
}
