import { observer, Observer } from 'mobx-react-lite'
import { Paper } from '@mui/material'
import { FCC } from 'types'
import { useItem } from 'features/page-datasets-settings'

export const Card: FCC = observer(({ children }) => {
  const item = useItem()

  return (
    <Paper variant={'property'}>
      <span>
        <Observer>{() => <>{item?.classKey}</>}</Observer>
      </span>
      <span>
        <Observer>{() => <>{item?.propKey}</>}</Observer>
      </span>
      {children}
    </Paper>
  )
})
