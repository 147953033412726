import { Avatar } from '@mui/material'


export type AvatarProps = {
  name: string
  role: number
  size: string
}

export const UserAvatar = ({ name, role, size }: AvatarProps) => {
  const getSize = () => {
    switch (size) {
      case 'small':
        return {
          width: 20,
          height: 20,
          fontSize: 10,
        }
      case 'medium':
        return {
          width: 36,
          height: 36,
          fontSize: 18,
        }
      case 'large':
        return {
          width: 56,
          height: 56,
          fontSize: 28,
        }
      default:
        return {}
    }
  }

  const getColor = () => {
    switch (role) {
      case 5:
        return {
          backgroundColor: '#FFCDD2',
          color: '#F44336',
        }
      case 6:
        return {
          backgroundColor: '#BBDEFB',
          color: '#1976D2',
        }
      case 7:
        return {
          backgroundColor: '#DCEDC8',
          color: '#7CB342',
        }
      default:
        return {
          backgroundColor: 'rgba(15, 31, 72, 0.1)',
          color: 'rgba(15, 31, 72, 0.3)',
        }
    }
  }

  const abbreviation = () => {
    const split = name.split(' ')
    return split[0][0] + (split.length > 1 ? split[1][0] : '')
  }

  return (
    <Avatar sx={{ ...getColor(), ...getSize() }}>
      {abbreviation()}
    </Avatar>
  )
}
