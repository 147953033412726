import { Box, TextField, Typography } from '@mui/material'


export type ReasonTextFieldProps = {
  reason: string
  onChangeReason: (value: string) => void
}

export const ReasonTextField = ({
  reason,
  onChangeReason,
}: ReasonTextFieldProps) => (
  <Box display={'flex'} flexDirection={'column'} gap={1}>
    <Typography color={'text.secondary'}>Введите свою причину</Typography>
    <TextField
      multiline
      rows={4}
      value={reason}
      placeholder="Текст"
      sx={{ width: '300px', height: '120px' }}
      onChange={(evt) => onChangeReason(evt.target.value)}
    />
  </Box>
)
