import axios from "axios"
import { format, parseISO } from 'date-fns'
import { useState, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import {
  Box,
  Table,
  Button,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material"
import {
  NavigateNext,
  NavigateBefore,
} from '@mui/icons-material'

import { DOMEN } from "api"
import { token } from "features/token"
import { Page, PageTitleProject, Grid } from "shared"


export const headers = { headers: token.headers }

export const DatasetMarkups = () => {
  const { datasetId } = useParams()
  const [next, setNext] = useState('')
  const [markups, setMarkups] = useState([])
  const [previous, setPrevious] = useState('')
  const [texts, setTexts] = useState<any[]>([])

  const getTextMarkup = (ms: any[]) => {
    ms.forEach(m => {
      axios.get(`${DOMEN}/${m.record.split('/').slice(3).join('/')}`, headers).then(res => {
        if (res.statusText === 'OK' && res?.data) {
          setTexts(prev => [...prev, {markupId: m.id, data: res.data.data}])
        }
      })
    })
  }

  const update = (res: any) => {
    if (res.statusText === 'OK' && res?.data) {
      setMarkups(res.data.results)
      getTextMarkup(res.data.results)
      setNext(res.data.next)
      setPrevious(res.data.previous)
    }
  }

  useEffect(() => {
    axios
    .get(`${DOMEN}/api/audio_markups/?record__dataset_id=${datasetId}`, headers)
    .then(res => update(res))
  }, [datasetId])

  const moveCursor = (ff?: boolean) => {
    axios.get(ff ? next : previous, headers).then(res => update(res))
  }

  return (
    <Page>
      <PageTitleProject />
      <TableContainer component={Grid}>
        <Table sx={{ color: 'black' }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Данные</TableCell>
              <TableCell align="center">ID пользователя</TableCell>
              <TableCell align="center">Дата Создания</TableCell>
              <TableCell align="right">Экспертная</TableCell>
              <TableCell align="right">Пропущено</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {markups.map((markup: any) => (
              <TableRow
                key={markup.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                component={Link}
                to={`${markup.id}`}
              >
                <TableCell component="th" scope="row">
                  {markup.id}
                </TableCell>
                <TableCell align="center">{texts.find(text => text.markupId === markup.id)?.data['text']?.slice(0, 30)}</TableCell>
                <TableCell align="center">{markup.user.split('/').slice(-2, -1)}</TableCell>
                <TableCell align="center">{format(parseISO(markup.created_at), 'HH:mm MM-dd-yyyy')}</TableCell>
                <TableCell align="right"><Checkbox disabled checked={markup.is_expert} /></TableCell>
                <TableCell align="right"><Checkbox disabled checked={markup.is_skipped} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box display="flex" flexDirection="row-reverse" m={2}>
          <Button onClick={() => moveCursor(true)} disabled={!next}><NavigateNext /></Button>
          <Button onClick={() => moveCursor()} disabled={!previous}><NavigateBefore /></Button>
        </Box>
      </TableContainer>
    </Page>
  )
}

export default DatasetMarkups
