import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import { useProject } from 'features/projects'

export const Field = observer<{ field: 'name' | 'description' }>(
  ({ field }) => {
    const project = useProject()

    return (
      <TextField
        autoFocus={field === 'name'}
        label={field === 'name' ? 'Название проекта' : 'Описание проекта'}
        value={project[field]}
        onChange={({ target }) => project.onChange({ [field]: target.value })}
      />
    )
  }
)
