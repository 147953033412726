import { action, makeAutoObservable, observable, reaction } from 'mobx'
import { alpha, colors } from '@mui/material'
import palette from 'theme/palette'
import { catalogs, category, classes, enableSelection } from './store'
import { currentOrder } from './computed'

type Data = Partial<Property>

const { grey } = colors

export class Prop {
  classKey = ''
  propKey = ''
  label = ''
  type: Property['type'] = ''
  order = 0
  catalogId = 0
  showForm = false
  formError = false

  constructor(private _c = '', private _p = '', private data: Data = {}) {
    this.setState()

    makeAutoObservable(this)

    reaction(
      () => classes.get(this.classKey)?.has(this.propKey),
      action((err) => (this.formError = Boolean(err)))
    )
  }

  private setState() {
    this.classKey = this._c
    this.propKey = this._p
    this.label = this.data.label ?? ''
    this.type = this.data.type ?? 'CHECK_BOX'
    this.catalogId = this.data.settings?.values.catalog_id ?? 0
    this.order = this.data.order ?? 0
  }

  setForm(bool: boolean) {
    this.showForm = bool
  }

  setClassKey(value: string) {
    this.classKey = value
  }

  setPropKey(value: string) {
    this.propKey = value.trim()
  }

  setLabel(value: string) {
    this.label = value
  }

  setType(value: string) {
    this.type = value as Property['type']
  }

  setCatalogId(value: string) {
    this.catalogId = +value
  }

  onClose() {
    this.setState()
    this.setForm(false)
  }

  onSubmit() {
    if (this.isNew) {
      !classes.has(this.classKey) &&
        classes.set(this.classKey, observable.map())

      const newProp = new Prop(this.classKey, this.propKey, {
        label: this.label,
        type: this.type,
        order: currentOrder.get() + 1,
        settings: this.settings,
      })

      classes.get(this.classKey)?.set(this.propKey, newProp)
      this.setState()
      this.setForm(false)
    } else {
      const prop = new Prop(this._c, this._p, {
        label: this.label,
        type: this.type,
        order: this.order,
        settings: this.settings,
      })

      classes.get(this._c)?.set(this._p, prop)
    }
  }

  onRemove() {
    classes.get(this._c)?.delete(this._p)
  }

  get key() {
    return `${this._c}_${this._p}`
  }

  get isNew() {
    return !this._c
  }

  get isRoot() {
    return this._c === 'ROOT'
  }

  get catalogName() {
    return catalogs.get(+this._c)?.name
  }

  get catalogColor() {
    const color =
      this.classKey === 'ROOT'
        ? palette.primary.main
        : this.classKey === 'COMMON'
        ? palette.secondary.main
        : catalogs.get(+this.classKey)?.color ?? grey[500]

    return alpha(color, 0.1)
  }

  get showSelect() {
    return this.type === 'COMBO_BOX'
  }

  get settings() {
    return this.showSelect && this.catalogId
      ? { values: { catalog_id: this.catalogId } }
      : undefined
  }

  get property(): Property {
    return {
      label: this.label,
      order: this.order,
      type: this.type,
      settings: this.settings,
    }
  }

  get disabledNewButton() {
    return (!category.get() && !enableSelection.get()) || this.showForm
  }

  private get disabledNewForm() {
    return [
      this.formError,
      !this.classKey,
      !this.propKey,
      !this.label,
      !this.type,
      this.showSelect && !this.catalogId,
    ].some(Boolean)
  }

  private get disabledEditForm() {
    return (
      (this.label === this.data.label && this.type === this.data.type) ||
      (this.showSelect && !this.catalogId)
    )
  }

  get disabledForm() {
    return this.isNew ? this.disabledNewForm : this.disabledEditForm
  }
}
