import { styled, InputBase  } from '@mui/material'


export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    padding: '10px 26px 10px 30px',
    alignSelf: 'left',
  },
  '.MuiSelect-icon': {
    opacity: 0,
    position: 'absolute',
    left: '0px',
    color: 'rgba(15, 31, 72, 0.3)',
  },
  '&:hover .MuiSelect-icon': {
    opacity: 1,
  },
	'&:active .MuiSelect-icon': {
    opacity: 1,
  },
}))
