import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { MenuItem, Skeleton, TextField } from '@mui/material'
import { loading, dataType } from 'features/settings-dataset'

const items = [
  {
    type: 'FILE_OBJECT', 
    name: 'Файл', 
  },
  {
    type: 'JSON_PATH', 
    name: 'Структурированный файл', 
  }
]

export const DataType = observer(() => {
  if (loading.get()) return <Skeleton variant={'button'} />

  return (
    <TextField
      select
      label={'Тип данных'}
      value={dataType.get()}
      onChange={action((e) => dataType.set(e.target.value))}
    >
      {items.map((i) => (
        <MenuItem key={i.type} value={i.type} children={i.name} />
      ))}
    </TextField>
  )
})
