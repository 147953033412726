import { useEffect, useState } from 'react'
import { Backdrop, Box, CircularProgress } from '@mui/material'

import { Markup } from './utils/type'
import { Filters, TopPanel } from './organisms'
import { getFiltered, getFilterValues } from './utils/utils'
import { MarkupsTable, MarkupsTableProps } from './filler/markups-table'


type MarkupsProps = MarkupsTableProps & {
  loaded: boolean
}

export const MarkupsTableWithPanel = ({
  loaded,
  markups,
}: MarkupsProps) => {
  const init = {
    user: '',
    is_skipped: '',
    created_at: '',
    is_honeypot: '',
  }
  const [filter, setFilter] = useState(init)
  const [filteredMarkups, setFilteredMarkups] = useState<Markup[]>([])

  useEffect(() => {
    if (markups.length) {
      const values = getFilterValues(filter)
      setFilteredMarkups(getFiltered(values, markups))
    }
  }, [markups])

  return (
    <Box>
      <TopPanel
        all={markups.length}
        skipped={markups.filter((el) => el.is_skipped).length}
      />
      <Filters
        filter={filter}
        onSelect={(value, key) =>
          setFilter((prev) => ({ ...prev, [key]: value }))
        }
        markups={markups}
        onResetFilter={() => {
          setFilter(init)
          setFilteredMarkups(markups)
        }}
        onSubmitFilter={(values) =>
          setFilteredMarkups(getFiltered(values, markups))
        }
      />
      {(loaded && markups.every((el) => el.time)) ? (
        <MarkupsTable markups={filteredMarkups} />
      ) : (
        <Backdrop data-loader open={!loaded}>
          <CircularProgress />
        </Backdrop>
      )}
    </Box>
  )
}
