import { observer } from 'mobx-react-lite'
import { Typography } from '@mui/material'
import { useCatalog } from 'features/catalogs'

export const Count = observer(() => {
  const catalog = useCatalog()

  if (catalog.fetch?.method === 'GET') return <></>

  return <Typography children={catalog.childList.length} />
})
