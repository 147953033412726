import * as Mui from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Selector } from 'markers/selectors'
import { useMarkup } from 'features/page-markup'

import markupProccess from './showers/state'


const Template = Mui.styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const Skeleton = observer(() => (
  <Template>
    <Mui.Skeleton variant={'button'} />
    <Mui.Skeleton variant={'button'} />
  </Template>
))

export const Classes = observer(() => {
  const { categories, markupComponents } = useMarkup()

  const handler = (element: SelectionT, type: string, value?: Value) => {
    markupProccess.onSelectionChange(
      element,
      type,
      categories,
      value,
    )
  }

  if (!markupComponents) return <Mui.Stack gap={2}><Skeleton /></Mui.Stack>

  return (
    <Mui.Stack gap={2}>
      <Selector
        markupComponents={markupComponents}
        categories={categories}
        onChange={handler}
        markups={markupProccess.selections}
      />
    </Mui.Stack>
  )
})
