import { Box, Typography } from "@mui/material"
import { observer } from "mobx-react-lite"

import { MarkupComponents } from "types"

import { Choositor } from "../molecules/choositor"
import { CompositeSelect } from "../molecules"
import * as utils from "../utils/parsers"


type Props = {
  markupComponents: MarkupComponents
  categories: Category[]
  onChange: (
    element: SelectionT,
    type: string,
    value?: Value,
  ) => void
  markups: SelectionT[]
}

export const Selector =  observer(({ markupComponents, categories, onChange, markups }: Props) => {
  const class_values = utils.categoriesFromParent(categories, utils.classId(markupComponents))
  
  return (
    <Box mt={1}>
      {markups.map(markup => (
        <Box key={markup.uuid}>
          <Typography hidden={!markup.selection?.value} align="center">
            {markup.selection?.value}
          </Typography>
          <CompositeSelect
            label=""
            onChange={(value) => onChange(markup, 'COMMON', value)}
            value={markup.category?.pk}
            values={class_values}
            index={markup.selection.index}
            color={markup.selection.color}
            onRemove={() => onChange(markup, 'REMOVE')}
          />
          <Box ml={5} mb={2} mt={1}>
            {utils.properties(markupComponents, 'COMMON').sort(e => e[1].order).map(elem => (
              <Choositor
                key={elem[1].label}
                property={elem[1]}
                categories={categories}
                onChange={(value) => onChange(markup, elem[0], value)}
                value={markup.properties[elem[0]]}
              />
            ))}
          </Box>
          <Box ml={5} mb={5}>
            {utils.properties(markupComponents, String(markup.category?.pk || '')).sort(e => e[1].order).map(elem => (
              <Choositor
                key={elem[1].label}
                property={elem[1]}
                categories={categories}
                onChange={(value) => onChange(markup, elem[0], value)}
                value={markup.properties[elem[0]]}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
})
