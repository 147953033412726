import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { CircularProgress, Backdrop } from '@mui/material'
import { Grid } from 'shared'
import { CatalogProvider, useCatalog } from 'features/catalogs'
import { parent, child } from './grid-cells'
import { GridRow } from './grid-row'

const NewCatalog = observer(() => {
  const catalog = useCatalog()

  return (
    <Grid data-collapse={!!catalog.newCatalog}>
      {catalog.newCatalog && <GridRow cells={parent} />}
    </Grid>
  )
})

const ChildList = observer(() => {
  const catalog = useCatalog()

  useEffect(() => catalog.onClear, [])

  return (
    <Grid data-collapse={catalog.showList}>
      {catalog.childList.map((c) => (
        <CatalogProvider key={c.id} value={c}>
          <GridRow cells={child} />
        </CatalogProvider>
      ))}
    </Grid>
  )
})

const ParentList = observer(() => (
  <>
    {useCatalog().parentList.map((c) => (
      <CatalogProvider key={c.id} value={c}>
        <GridRow cells={parent} />
        <ChildList />
      </CatalogProvider>
    ))}
  </>
))

const Loader = observer(() => (
  <Backdrop data-loader open={useCatalog().fetch?.method === 'GET'}>
    <CircularProgress />
  </Backdrop>
))

export const GridBody = observer(() => (
  <>
    <NewCatalog />
    <ParentList />
    <Loader />
  </>
))
