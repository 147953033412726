import { Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Icon } from 'shared'
import { useProject } from 'features/projects'
import { dataset } from 'features/settings-dataset'

export const PageTitle = observer(() => {
  const project = useProject()

  return (
    <Typography
      variant="h1"
      display={'flex'}
      alignItems={'center'}
      gap={1}
      color={'text.disabled'}
    >
      {project.name || '...'}
      <Icon icon={'arrow'} sx={{ transform: 'rotate(-90deg)' }} />
      <Stack component={'span'} color={'text.primary'}>
        {dataset.get()?.name || '...'}
      </Stack>
    </Typography>
  )
})
