import { useEffect } from 'react'
import { action, computed, reaction, values } from 'mobx'
import { observer, Observer } from 'mobx-react-lite'
import { Checkbox, Skeleton } from '@mui/material'
import { useCatalog, catalogs } from 'features/catalogs'

export const Head = observer(() => {
  const ct = useCatalog()

  if (ct.fetch) return <Skeleton variant={'checkbox'} />

  if (ct.id && ct.newCatalog) return <></>

  const arr = values(catalogs)

  const checked = computed(() =>
    arr.filter((c) => !c.fetch).some((c) => c.selected)
  ).get()

  const indeterminate = computed(
    () => checked && !arr.filter((c) => !c.fetch).every((c) => c.selected)
  ).get()

  return (
    <Observer>
      {() => (
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={action(({ target: { checked } }) => {
            arr.filter((c) => !c.fetch).forEach((c) => (c.selected = checked))
          })}
        />
      )}
    </Observer>
  )
})

export const ParentRow = observer(() => {
  const ct = useCatalog()

  useEffect(() => {
    const disposer = reaction(
      () => ct.childList.some((c) => c.selected),
      action((checked) => (ct.selected = checked))
    )
    return () => disposer()
  }, [ct])

  if (ct.fetch) return <Skeleton variant={'checkbox'} />

  if (ct.newCatalog) return <></>

  const checked = computed(() => ct.childList.some((c) => c.selected)).get()
  const indeterminate = computed(
    () => checked && ct.childList.length !== ct.selectedChilList.length
  ).get()

  return (
    <Observer>
      {() => (
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={action(({ target: { checked } }) => {
            ct.childList.forEach((c) => (c.selected = checked))
          })}
        />
      )}
    </Observer>
  )
})

export const ChildRow = observer(() => {
  const ct = useCatalog()

  if (ct.fetch) return <Skeleton variant={'checkbox'} />

  if (ct.newCatalog) return <></>

  return (
    <Checkbox
      checked={ct.selected}
      onChange={action(({ target: { checked } }) => {
        ct.selected = checked
      })}
    />
  )
})
