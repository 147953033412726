import { useCallback } from 'react'
import { Graphics } from '@inlet/react-pixi'

export type PointerProps = {
  width: number
  height: number
  progress: number
}

export const Pointer = ({
  width,
  height,
  progress = 0,
}: PointerProps) => {
  const draw = useCallback(
    (g: any) => {
      g.clear()
      g.beginFill(0x29313d)
      g.drawRect(width * progress, 0, 2, height)
    },
    [width, height, progress],
  )

  return <Graphics draw={draw} />
}