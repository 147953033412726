import { Cancel, PanoramaFishEye } from '@mui/icons-material'
import { FormControlLabel, Checkbox as MuiCheckbox } from "@mui/material"


export const Checkbox = ({
  label, onChange, value, invert,
}: CheckboxProps) => (
    <FormControlLabel
      control={
        <MuiCheckbox
          onChange={evt => onChange && onChange(evt.target.checked)}
          checked={Boolean(value)}
          checkedIcon={invert ? <Cancel /> : undefined}
          icon={invert ? <PanoramaFishEye /> : undefined}
        />
      }
      sx={{justifyContent: "flex-end"}}
      labelPlacement="start"
      label={label}
    />
)
