import { Components, Theme } from '@mui/material'

export default {
  defaultProps: {
    size: 'small',
    color: 'default',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.text.disabled,

      ':hover, :focus-within': {
        color: theme.palette.text.secondary,
      },
    }),
  },
} as Components<Theme>['MuiRadio']
