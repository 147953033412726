import { Config } from 'types'
import { setConfig } from 'features/settings-dataset'


const makeOrder = (config: Config) => {
  const orders: Array<number> = []
  const keys = Object.keys(config?.markup_components?.classes)
  keys.forEach((key) =>
    Object.keys(config?.markup_components?.classes[key].properties).forEach(
      (property) =>
        orders.push(
          Number(
            config?.markup_components?.classes[key].properties[property].order
          )
        )
    )
  )
  return !orders.length ? 0 : Math.max(...orders) + 1
}

export const makeProperties = (
  type: boolean,
  property: string,
  config: any
) => {
  const configCopy = config
  if (!type) {
    if (configCopy?.markup_components?.classes?.ROOT?.properties) {
      const keys = Object.keys(
        configCopy?.markup_components?.classes?.ROOT?.properties
      )
      if (keys.includes(property) && keys.length > 1) {
        delete configCopy.markup_components.classes.ROOT.properties[property]
        setConfig(configCopy)
      } else if (keys[0] === property && keys.length === 1) {
        delete configCopy.markup_components.classes.ROOT
        setConfig(configCopy)
      }
    }
  } else if (type && configCopy?.markup_components?.classes?.ROOT?.properties) {
    configCopy.markup_components.classes.ROOT.properties[property] = {
      label: property === 'record' ? 'Озвучка' : 'Транскрипция',
      order: makeOrder(configCopy),
      type: property === 'record' ? 'AUDIO_RECORD' : 'INPUT_BOX_TEXT',
    }
    setConfig(configCopy)
  } else if (property && config) {
    configCopy.markup_components.classes.ROOT =
      property === 'record'
        ? {
            properties: {
              record: {
                label: 'Озвучка',
                order: makeOrder(configCopy),
                type: 'AUDIO_RECORD',
              },
            },
          }
        : {
            properties: {
              transcription: {
                label: 'Транскрипция',
                order: makeOrder(configCopy),
                type: 'INPUT_BOX_TEXT',
              },
            },
          }
    setConfig(configCopy)
  }
}
