import { makeAutoObservable } from 'mobx'
import { SelectionsState } from './selections'
import { Task } from './state'

export class MarkupTextState {
  constructor(private task: NonNullable<Task>, private classes: SelectionsState) {
    makeAutoObservable(this)
  }

  onMouseUp = () => {
    const selection = document.getSelection()
    if (!selection) return
    if (selection.isCollapsed) return
    if (!selection.toString().trim()) return

    const { anchorOffset, focusOffset } = selection

    selection.collapse(null)
    this.classes.onPush(anchorOffset, focusOffset)
  }

  get layers() {
    return this.classes.items
  }

  get text() {
    return this.task.data.edges.at(0)?.node.data.text
  }
}
