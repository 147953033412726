import { styled } from '@mui/system'
import { Button } from '@mui/material'

export const StyledRedButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFCDD2',
  color: '#F44336',
  ':hover, :active, :focus': {
    backgroundColor: '#FFCDD2',
    color: '#F44336',
  },
}))
