import { action, makeAutoObservable } from 'mobx'
import { queries } from 'api'
import { Fetch, ResponseLogin } from 'types'
import { FieldState } from 'features/filed'
import { token } from 'features/token'

export class FormLoginState {
  fetch: null | Fetch = null
  username = new FieldState('Логин')
  password = new FieldState('Пароль')

  constructor() {
    makeAutoObservable(this)
  }

  submit = () => {
    this.fetch = {
      method: 'POST',
      url: '/graphql/',
      data: {
        query: queries.tokenAuth,
        variables: {
          username: this.username.value,
          password: this.password.value,
        },
      },
      onResponse: action(() => (this.fetch = null)),
      onSuccess: action(({ body }: ResponseLogin) => {
        const { tokenAuth } = body.data

        tokenAuth && token.set(tokenAuth.token)
      }),
    }
  }

  get isDisabled() {
    return !this.username.value.trim() || !this.password.value.trim()
  }

  get isLoading() {
    return Boolean(this.fetch)
  }
}
