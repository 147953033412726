import { action, makeAutoObservable, toJS } from 'mobx'
import { queries } from 'api'
import {
  Fetch,
  TaskByProject,
  ResponseCreateMarkup,
  ResponseTaskByProject,
  CreateMarkupVariables,
  ResponseAllCategories,
} from 'types'

import * as Util from 'utils'
import { TimerState } from 'features/timer'
import { ZoomState } from 'features/zoom'
import { SelectionsState } from './selections'
import { MarkupTextState } from './markup-text'


export type Task = TaskByProject['taskByProject']

export class MarkupState {
  timer: null | TimerState = null
  zoom = new ZoomState()
  selections = new SelectionsState()

  fetch: null | Fetch = null
  task: Task = null
  markupText: null | MarkupTextState = null
  categories: Category[] = []

  constructor() {
    makeAutoObservable(this)
  }

  static getType(task: Task) {
    return task?.dataset.config.display_component?.type
  }

  static getText(task: Task) {
    return task?.data.edges[0].node.data.text
  }

  private set = (state: Partial<MarkupState>) => {
    Object.assign(this, state)
  }

  private closeFetch = () => {
    this.fetch = null
  }

  private success = (res: ResponseTaskByProject) => {
    const { data } = res.body
    const { taskByProject: task } = data

    if (!task) return

    this.timer = new TimerState(
      new Date(task.expiredAt),
      new Date(task.issuedAt)
    )

    MarkupState.getType(task) === 'TEXT' &&
      this.set({ markupText: new MarkupTextState(task, this.selections) })
    this.set({ task: { ...task } })

    this.getCategoriesOnProject()
  }

  private successCategory = (res: ResponseAllCategories) => {
    const { data } = res.body
    const { edges } = data.allCategories

    if (!edges.length) return

    this.set({ categories: edges.map(edge => edge.node) })
  }

  getTask = () => {
    this.fetch = {
      method: 'POST',
      url: '/graphql/',
      data: {
        query: queries.tackByProject,
        variables: { id: Util.getProjectId() },
      },
      onStart: this.onClear,
      onResponse: this.closeFetch,
      onSuccess: this.success,
    }
  }

  getCategoriesOnProject = () => {
    this.fetch = {
      method: 'POST',
      url: '/graphql/',
      data: {
        query: queries.categoriesOnProject,
        variables: { id: Util.getProjectId() },
      },
      onStart: this.onClearCategories,
      onResponse: this.closeFetch,
      onSuccess: this.successCategory,
    }
  }

  onClear = () => {
    this.markupText = null
  }

  onClearCategories = () => {
    this.categories = []
  }

  onSkip = () => {
    this.fetch = {
      method: 'POST',
      url: '/graphql/',
      data: {
        query: queries.createMarkup,
        variables: {
          markups: [
            {
              record: this.task?.data.edges[0].node.id,
              isSkipped: true,
              data: { entities: [] },
            },
          ],
          task: this.task?.id,
        } as CreateMarkupVariables,
      },
      onStart: action(() => {
        this.timer = null
        this.task = null
      }),
      onResponse: this.closeFetch,
      onSuccess: ({ body }: ResponseCreateMarkup) => {
        body.data.createMarkup?.ok && this.getTask()
      },
    }
  }

  onConfirm = (entities: any) => {
    this.fetch = {
      method: 'POST',
      url: '/graphql/',
      data: {
        query: queries.createMarkup,
        variables: {
          markups: [
            {
              record: this.task?.data.edges[0].node.id,
              isSkipped: false,
              data: { entities: entities },
            },
          ],
          task: this.task?.id,
        } as CreateMarkupVariables,
      },
      onStart: action(() => {
        this.timer = null
        this.task = null
      }),
      onResponse: this.closeFetch,
      onSuccess: ({ body }: ResponseCreateMarkup) => {
        body.data.createMarkup?.ok && this.getTask()
      },
    }
  }

  get isLoading() {
    return Boolean(this.fetch)
  }

  get isDisabled() {
    return this.isLoading || this.timer?.isOver || !this.task
  }

  get type() {
    return Util.getTaskType(this.task)
  }

  get markupComponents() {
    return Util.getMarkupComponent(this.task)
  }

  get catalogIds(): number[] {
    if (this.task) {
      return Util.getCatalogIds(this.task)
    }
    return []
  }

  get classCatalogId() {
      return Util.getClassCatalogId(this.task)
  }

  get data() {
    return Util.getDataFromTask(this.task)
  }
}
