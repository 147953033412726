import { useRef, useEffect } from 'react'
import { timeoutScroll } from 'utils'
import { useClass, useClasses } from 'features/page-markup'

export const useOutClassesScroll = <T extends HTMLElement>() => {
  const ref = useRef<T>(null)
  const cls = useClass()
  const clss = useClasses()

  useEffect(() => {
    const ts = timeoutScroll(clss.isOver && cls.isOver, ref)
    return () => clearTimeout(ts)
  }, [clss.isOver, cls.isOver])

  return ref
}

export const useOverClassesScroll = <T extends HTMLElement>() => {
  const ref = useRef<T>(null)
  const cls = useClass()
  const clss = useClasses()

  useEffect(() => {
    const ts = timeoutScroll(!clss.isOver && cls.isOver, ref)
    return () => clearTimeout(ts)
  }, [clss.isOver, cls.isOver])

  return ref
}
