import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Box, Slide, SlideProps, Stack, Typography } from '@mui/material'
import { ItemProvider, useConfig } from 'features/page-datasets-settings'
import { New, Edit, Close, Submit, Delete } from './buttons'
import { Class, Label, Type, Data, Value } from './fields'
import { Card } from './card'

type SP = Pick<SlideProps, 'container' | 'direction'>

const FORM_ID = 'form-property'

const Items = observer(() => (
  <>
    {useConfig().items.map((item) => (
      <ItemProvider key={item.propKey} value={item}>
        <Card>
          <Edit />
        </Card>
      </ItemProvider>
    ))}
  </>
))

const List = observer<SP>((props) => {
  const config = useConfig()

  return (
    <Slide in={!config.item.classKey} unmountOnExit {...props}>
      <Stack gap={2}>
        <Items />
      </Stack>
    </Slide>
  )
})

const Form = observer<SP>((props) => {
  const config = useConfig()

  return (
    <Slide in={!!config.item.classKey} unmountOnExit {...props}>
      <Stack
        zIndex={'modal'}
        bgcolor={'background.paper'}
        gap={2}
        sx={{ form: { display: 'contents' } }}
      >
        <Card />
        <form id={FORM_ID} onSubmit={(e) => e.preventDefault()}>
          <Class />
          <Label />
          <Type />
          <Data />
          <Value />
        </form>
      </Stack>
    </Slide>
  )
})

export const Properties = observer(() => {
  const ref = useRef(null)
  const config = useConfig()

  return (
    <ItemProvider value={config.item}>
      <Stack direction={'row'} alignItems={'center'} gap={1} px={3} py={1}>
        <Typography
          children={'Свойство объекта'}
          variant={'subtitle1'}
          flex={1}
        />
        <Delete />
        <Submit formId={FORM_ID} />
        <Close />
        <New />
      </Stack>
      <Box
        ref={ref}
        display={'grid'}
        px={3}
        my={1}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          '> div': { gridArea: '1 / 1' },
        }}
      >
        <List container={ref.current} direction={'left'} />
        <Form container={ref.current} direction={'right'} />
      </Box>
    </ItemProvider>
  )
})
