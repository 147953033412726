import axios from 'axios'
import { toJS } from 'mobx'
import { mapValues } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { token } from 'features/token'
import { useMarkup } from 'features/page-markup'
import { AsyncLoader } from 'markers/async-loader/async-loader'

import markupProccess from './state'
import * as uuid from 'uuid'


export const Shower = observer(() => {
  const { type, isLoading, data, timer, task, categories } = useMarkup()
  const [txtContent, setTxtContent] = useState('')

  useEffect(() => {
    const headersCheck = token.headers || {}
    let taskConfig = task?.dataset.config.display_component

    if (taskConfig?.type === 'TEXT' && taskConfig.data.type === 'FILE_OBJECT' && !txtContent) {
      axios
      .get(data, {headers: headersCheck})
      .then(res => res.data)
      .then(res => setTxtContent(res))
    }
  })

  // Preliminary markup
  useEffect(() => {
    if (task) {
      const taskData = toJS(task)
      const categoriesData = toJS(categories)
      const preliminaryEntities = taskData.data.edges[0].node.preliminaryMarkupData?.entities

      if (preliminaryEntities && preliminaryEntities[0]?.properties) {
        const propertiesROOT = preliminaryEntities.find((ent: any) => ent.class.class_id === "ROOT")?.properties
        markupProccess.setRootSelections(mapValues(propertiesROOT, (o: any) => o.value))

        const common = preliminaryEntities.filter((ent: any) => !isNaN(+ent.class.class_id))
        markupProccess.setCommonSelections(
          common.map((com: any) => ({
            uuid: uuid.v4(),
            selection: com.selection,
            category: categoriesData.find(cat => cat.pk === Number(com.class.class_id)),
            properties: mapValues(com.properties, (o: any) => o.value),
          }))
        )
      }
    }
  }, [task, categories])

  useEffect(() => (() => markupProccess.clear()), [])

  const onChangeHandle = (sel: SelectT) => {
    markupProccess.modifySelection(sel)
  }

	const onChangeMagic = (selects: SelectT[]) => {
    markupProccess.modifySelectionMagic(selects)
  }

  const onRemove = (index: number | string) => {
    markupProccess.onRemove(index)
  }

  if (timer?.isOver) markupProccess.clear()


  return timer?.isOver || isLoading || !type ? null : (
    <AsyncLoader
      headers={token.headers}
      component={type}
      data={!txtContent ? data : txtContent}
      selections={markupProccess.selections.map(sel => sel.selection)}
			defaultCategoryPk={markupProccess?.defaultCategory?.pk}
			datasetId={Number(atob(String(task?.dataset?.id)).split(':')[1])}
      onChange={onChangeHandle}
			onChangeMagic={onChangeMagic}
      onRemove={onRemove}
    />
  )
})

