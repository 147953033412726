import { TextField } from '@mui/material'


export type TextFieldNameProps = {
  defaultName: string
  onChange: (value: string) => void
}
export const TextFieldName = ({
  defaultName,
  onChange,
}: TextFieldNameProps) => {
  return (
    <TextField
      label="Название датасета"
      variant="outlined"
      onChange={(e: any) => onChange(e.target.value)}
      defaultValue={defaultName}
    />
  )
}
