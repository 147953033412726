import { MenuItem } from '@mui/material'

import { StyledTextField } from '../styled-textfield'


type SkippedFilterProps = {
  value: string
  values: any[]
  onSelect: (value: any, name: string) => void
}

export const SkippedFilter = ({
  value,
  values,
  onSelect,
}: SkippedFilterProps) => (
  <StyledTextField
    select
    fullWidth
    value={value}
    name={'is_skipped'}
    label={'Пропущена'}
    onChange={(evt) => onSelect(evt.target.value, 'is_skipped')}
  >
    {values.map((el) => (
      <MenuItem key={`${el}`} value={el}>
        {el ? 'да' : 'нет'}
      </MenuItem>
    ))}
  </StyledTextField>
)
