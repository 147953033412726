import { observer } from 'mobx-react-lite'
import { Skeleton, TextField } from '@mui/material'
import { useCatalog } from 'features/catalogs'

export const Color = observer(() => {
  const ct = useCatalog()

  if (ct.fetch?.method === 'GET') return <Skeleton variant={'icon'} />

  const value = ct.newCatalog?.color ?? ct.color

  return (
    <TextField
      type={'color'}
      sx={{ '--color': value }}
      value={value}
      onChange={({ target: { value } }) => {
        if (value.startsWith('#'))
          ct.newCatalog ? (ct.newCatalog.color = value) : (ct.color = value)
      }}
    />
  )
})

export const Name = observer(() => {
  const ct = useCatalog()

  if (ct.fetch?.method === 'GET') return <Skeleton />

  const value = ct.newCatalog?.name ?? ct.name

  return (
    <TextField
      placeholder={'Название справочника'}
      data-inline={!ct.newCatalog}
      value={value}
      onChange={({ target: { value } }) => {
        ct.newCatalog ? (ct.newCatalog.name = value) : (ct.name = value)
      }}
      sx={{
        input: {
          fontWeight: 500,

          '::placeholder': {
            fontWeight: 400,
          },
        },
      }}
    />
  )
})

export const Desc = observer(() => {
  const ct = useCatalog()

  if (ct.fetch?.method === 'GET') return <Skeleton />

  const value = ct.newCatalog?.description ?? ct.description

  return (
    <TextField
      placeholder={ct.newCatalog ? 'Описание справочника' : ''}
      data-inline={!ct.newCatalog}
      value={value}
      onChange={({ target: { value } }) => {
        ct.newCatalog
          ? (ct.newCatalog.description = value)
          : (ct.description = value)
      }}
    />
  )
})
