import { Components, Theme } from '@mui/material'

export default {
  defaultProps: {
    disableRipple: true,
  },

  variants: [
    {
      props: { hidden: true },
      style: { display: 'none' },
    },
    {
      props: { 'data-class-button': true },
      style: ({ theme }) => ({
        width: 24,
        aspectRatio: '1 / 1',
        display: 'grid',
        placeContent: 'center',
        borderRadius: 8,
        color: theme.palette.common.white,
        background: theme.palette.grey[500],

        ':where(:hover,:focus) + mark': {
          opacity: 1,
        },
      }),
    },
  ],
} as Components<Theme>['MuiButtonBase']
