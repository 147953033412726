import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'
import { Page, PageTitleDataset } from 'shared'
import { PageProvider } from 'features/page-datasets-settings'
import { HowMarkup } from './how-markup'
import { BlockSettings } from './block-settings'
import { BlockJson } from './block-json'

export const DatasetsSettings = observer(() => (
  <PageProvider>
    <Page>
      <PageTitleDataset />
      <Stack
        flex={1}
        direction={'row'}
        gap={3}
        overflow={'hidden'}
        m={-2}
        p={2}
      >
        <HowMarkup />
        <BlockSettings />
        <BlockJson />
      </Stack>
    </Page>
  </PageProvider>
))
