import { useState } from 'react'
import {
  Box,
  Paper,
  Table,
	Backdrop,
  TableRow,
  Checkbox,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material'

import { Order, User } from '../type'
import { UsersTableHeader } from '../molecules'
import { getComparator, getIndeterminate } from '../utils'
import { UserAvatar, CustomCheckbox, UserRating, Select } from '../atoms'

type Props = {
  users: User[]
  roles: {
    name: string
    pk: number
  }[]
  onCheckToggle: (userId: number) => void
  onChangeRating: (id: number, value: number) => void
  onChangeRole: (id: number, oldRole: Value, newRole: Value) => void
}

export const UsersTable = ({
  roles,
  users,
  onChangeRole,
  onCheckToggle,
  onChangeRating,
}: Props) => {
  const [orderBy, setOrderBy] = useState('role')
  const [order, setOrder] = useState<Order>('desc')

  const handleSort = (property: string) => {
    setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleCheckAll = () => {
    if (users.every((user) => user.checked === true)) {
      users.forEach((user) => onCheckToggle(user.id))
    } else {
      users
        .filter((user) => !user.checked)
        .forEach((user) => onCheckToggle(user.id))
    }
  }

  return (
    <Box sx={{ width: '100%' }}>{users.length ? (
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 750 }}>
            <UsersTableHeader
              order={order}
              orderBy={orderBy}
              onSort={handleSort}
              onCheckAll={handleCheckAll}
              indeterminate={getIndeterminate(users)}
              value={users.every((user) => user.checked === true)}
            />
            
              <TableBody>
                {users.sort(getComparator(order, orderBy)).map((user) => {
                  return (
                    <TableRow hover key={user.id}>
                      <TableCell>
                        <Checkbox
                          indeterminate={false}
                          checked={user.checked}
                          onChange={() => onCheckToggle(user.id)}
                        />
                      </TableCell>
                      <TableCell padding="checkbox" align="left">
                        <UserAvatar
                          size={'medium'}
                          name={user.username}
                          role={user.role || 0}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          fontWeight={500}
                          color={user.role ? 'text.primary' : 'text.disabled'}
                        >
                          {user.username}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={400} component="div">
                          <Select
                            roles={roles}
                            value={user.role || 0}
                            isGrey={!user.role}
                            onChange={(value) =>
                              onChangeRole(user.id, value, user.role)
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={400} color="text.secondary">
                          {user.email}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <UserRating
                          value={user.rating || 0}
                          onChange={(value) => onChangeRating(user.id, value)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {Boolean(user.role) && <CustomCheckbox />}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
           
          </Table>
        </TableContainer>
      </Paper> ) : (
              <Backdrop data-loader open={true}>
                <CircularProgress />
              </Backdrop>
            )}
    </Box>
  )
}
