import { observer } from 'mobx-react-lite'
import { createContext, useContext } from 'react'
import { FCC } from 'types'
import { Prop } from './prop'

const PropContext = createContext({} as Prop)

export const useProp = () => useContext(PropContext)

export const PropProvider: FCC<{ value: Prop }> = observer((props) => {
  return <PropContext.Provider {...props} />
})
