import { observer } from 'mobx-react-lite'
import { Stack, styled, Typography } from '@mui/material'
import { PropProvider, currentPropsList, Prop } from 'features/settings-dataset'

import { NewPropBtn } from './buttons'
import { PropForm } from './prop-form'
import { PropCard } from './prop-card'

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 3),
  margin: theme.spacing(1, 0),
  display: 'grid',
  gap: theme.spacing(2),
  overflow: 'auto',
}))

const Title = observer(() => (
  <Typography children={'Свойствo объекта'} variant={'subtitle1'} flex={1} />
))

const PropList = observer(() => (
  <>
    {currentPropsList.get().map((prop) => (
      <PropProvider key={prop.key} value={prop}>
        <PropCard />
        <PropForm />
      </PropProvider>
    ))}
  </>
))

export const Properties = observer(() => (
  <PropProvider value={new Prop()}>
    <Root>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        top={0}
        position={'sticky'}
        bgcolor={'background.paper'}
        pb={1}
        zIndex={2}
      >
        <Title />
        <NewPropBtn />
      </Stack>
      <PropForm />
      <PropList />
    </Root>
  </PropProvider>
))
