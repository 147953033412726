import { Button, Stack } from '@mui/material'

import {
  getDisabled,
  getFilterValues,
  getValuesForRender,
} from '../utils/utils'
import { Filter, Markup } from '../utils/type'
import { DateFilter, SkippedFilter, UserFilter, TypeFilter} from '../atoms'


type FiltersProps = {
  filter: Filter
  markups: Markup[]
  onResetFilter: () => void
  onSubmitFilter: (filter: any) => void
  onSelect: (value: string, key: string) => void
}

export const Filters = ({
  filter,
  markups,
  onSelect,
  onResetFilter,
  onSubmitFilter,
}: FiltersProps) => (
  <Stack direction={'row'} mb={2} gap={3}>
    <UserFilter
      value={filter.user}
      values={getValuesForRender(markups, 'user')}
      onSelect={(value) => onSelect(value, 'user')}
    />
		<TypeFilter
      value={filter.is_honeypot}
      values={getValuesForRender(markups, 'is_honeypot')}
      onSelect={(value) => onSelect(value, 'is_honeypot')}
    />
		
    <SkippedFilter
      value={filter.is_skipped}
      values={getValuesForRender(markups, 'is_skipped')}
      onSelect={(value) => onSelect(value, 'is_skipped')}
    />
    <DateFilter
      value={filter.created_at}
      values={getValuesForRender(markups, 'created_at')}
      onSelect={(value) => onSelect(value, 'created_at')}
    />
    <Stack direction={'row'} gap={1}>
      <Button
        type={'submit'}
        variant={'contained'}
        children={'Применить'}
        disabled={getDisabled(filter)}
        onClick={() => onSubmitFilter(getFilterValues(filter))}
      />
      <Button
        children={'Сбросить'}
        disabled={getDisabled(filter)}
        onClick={() => onResetFilter()}
      />
    </Stack>
  </Stack>
)
