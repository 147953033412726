import { observer } from 'mobx-react-lite'
import * as Mui from '@mui/material'
import { useMarkup } from 'features/page-markup'
import { useNavigate, useParams } from 'react-router-dom'

const Loader = observer(() =>
  useMarkup().isLoading ? <Mui.CircularProgress /> : null
)

const Reset = observer(() => {
  const markup = useMarkup()

  return markup.timer?.isOver && !markup.isLoading ? (
    <Mui.Button
      variant={'contained'}
      onClick={markup.getTask}
      children={'Получить задание'}
    />
  ) : null
})

const GoBack = observer(() => {
  const markup = useMarkup()
  const navigate = useNavigate()
  let { projectId } = useParams();

  return markup.task === null && !markup.isLoading ? (
    <Mui.Box display="flex" flexDirection="column">
      <Mui.Typography variant="h2" mb={2}>
        Заданий больше нет
      </Mui.Typography>
      <Mui.Button
        variant={'contained'}
        onClick={() => navigate('/project/' + projectId)}
        children={'Вернуться'}
      />
    </Mui.Box>
  ) : null
})

export const Modal = observer(() => (
  <Mui.Backdrop data-loader open={useMarkup().isDisabled}>
    <Loader />
    <Reset />
    <GoBack />
  </Mui.Backdrop>
))
