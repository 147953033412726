import loadable from '@loadable/component'


type Props = MarkupComponent & {
  component: MarkupComponentType
}

const AsyncMarkupComponent = loadable(
  ({ component }: Props) => import(`markers/${component.toLowerCase()}`),
  { cacheKey: ({ component }) => component }
)

export const AsyncLoader = (args: Props) => <AsyncMarkupComponent {...args} />
