import { observer } from 'mobx-react-lite'
import * as Mui from '@mui/material'
import * as Shared from 'shared'
import { MarkupProvider } from 'features/page-markup'
import { Actions } from './actions'
import { Classes } from './classes'
import { Modal } from './modal'
import { Selections } from './selections'
import { Timer } from './timer'
import { Toolbar } from './toolbar'
import { Shower } from './showers/shower'
import { DefaultCategory } from './defaultCategory'
import { RootSelection } from './rootSelection'

export const Markup = observer(() => (
  <MarkupProvider>
    <Shared.Page>
      <Shared.PageTitleProject />
      <Mui.Paper sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        <Mui.Stack flex={1} gap={1} p={3} position={'relative'}>
          <Timer />
          {/* <Toolbar /> */}
          <Modal />
          <Shower />
        </Mui.Stack>
        <Mui.Divider orientation={'vertical'} />
        <Mui.Stack flexBasis={340} gap={3} mt={3} mb={2}>
          <Mui.Stack px={3} gap={3}>
            <Actions />
            <DefaultCategory />
          </Mui.Stack>
          <Mui.Divider />
          <Mui.Stack px={3} gap={3} overflow="scroll">
            <RootSelection />
            <Classes />
          </Mui.Stack>
          <Selections />
        </Mui.Stack>
      </Mui.Paper>
    </Shared.Page>
  </MarkupProvider>
))
