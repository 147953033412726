import { Button, Stack } from '@mui/material'

import { StyledRedButton } from '../../styled-red-button'


type CancelNextButtonsProps = {
  onClick: (value: string) => void
}

export const CancelNextButtons = ({ onClick }: CancelNextButtonsProps) => (
  <Stack direction={'row'} gap={1} justifyContent={'center'}>
    <StyledRedButton
      children={'Отклонить'}
      variant={'contained'}
      onClick={() => onClick('reject')}
    />
    <Button
      children={'Следующая'}
      variant={'contained'}
      onClick={() => onClick('next')}
    />
  </Stack>
)
