import { Backdrop, Button, CircularProgress } from '@mui/material'
import { ChangeEventHandler } from 'react'
import { observer } from 'mobx-react-lite'

import { useDataset, usePage } from 'features/datasets'
import { useProject } from 'features/projects'


type E = ChangeEventHandler<HTMLInputElement>

export const Loader = observer(() => {
  const dataset = useDataset()

  return (
    <Backdrop data-loader open={!!dataset.fetch}>
      <CircularProgress size={20} />
    </Backdrop>
  )
})

type newFileProps = {
  setIsAudioModal: (value: any) => void
  setFiles: (files: File[]) => void
}
export const NewFile = observer(
  ({ setIsAudioModal, setFiles }: newFileProps) => {
    const page = usePage()
    const dataset = useDataset()
    const project = useProject()

    const handleChange: E = (e) => {
      const { files } = e.currentTarget
      const filesArray: File[] = []
      if (files?.length) {
        for (let i = 0; i < files.length; i++) {
          let file = files.item(i)
          if (file) {
            filesArray.push(file)
          }
        }
        setFiles(filesArray)
      }
      if (
        project?.type?.toString().toLowerCase() === 'audio' &&
        filesArray.length
      ) {
        setIsAudioModal(true)
      } else {
        filesArray.length && dataset?.onCreate(filesArray)
      }
    }

    return (
      <Button
        component={'label'}
        variant={'contained'}
        disabled={!!dataset.fetch || !!page.fetch}
      >
        Новый файл
        <input multiple type={'file'} hidden onChange={handleChange} />
        <Loader />
      </Button>
    )
  }
)
