import * as Mui from '@mui/material'

export { ReactComponent as Logo } from 'assets/logo.svg'

export const Template = Mui.styled('div')({
  display: 'flex',
  height: '100vh',
  overflow: 'auto',
})

export const Toolbar = Mui.styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 0),
  background: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  flexBasis: 64,
  alignItems: 'center',
  gap: theme.spacing(3),
  borderRight: `1px solid ${theme.palette.divider}`,
  overflow: 'auto',
}))
