import { useEffect } from 'react'
import { action } from 'mobx'
import { getData } from 'api'
import { getDatasetId, getProjectId } from 'utils'
import { ResponseDataset, ResponseRecords, ResponseCatalogs } from 'types'
import { PageState } from './state'

export function useInitialFetch(page: PageState) {
  useEffect(() => {
    const project_id = getProjectId()
    const dataset_id = getDatasetId()

    const fetches = [
      getData(`/api/audio_dataset/${dataset_id}/`),
      getData('/api/audio_records/').query({ dataset_id, page_size: 1 }),
      getData('/api/audio_category/').query({ project_id, level: 0 }),
    ]

    const actions = [
      action((res: ResponseDataset) => {
        page.dataset = res.body
        page.config.setConfig(res.body.config)
      }),
      action((res: ResponseRecords) => {
        page.recordData = res.body.results[0].data
      }),
      action((res: ResponseCatalogs) => {
        res.body.results.forEach((c) => page.catalogs.set(c.id, c))
      }),
      action(() => {
        page.isLoading = false
      }),
    ]

    Promise.all(fetches).then((res) => {
      actions.forEach((action, i) => action(res[i]))
    })

    return () => fetches.forEach((f) => f.abort())
  }, [page])
}

export function usePageReactions(page: PageState) {
  useEffect(() => {
    const keydown = ({ ctrlKey, shiftKey, code }: KeyboardEvent) => {
      if (ctrlKey && shiftKey && code.endsWith('S')) page.patch()
    }

    window.addEventListener('keydown', keydown)

    return () => window.removeEventListener('keydown', keydown)
  }, [page])
}
