import { Components, Theme } from '@mui/material'

export default {
  defaultProps: {
    color: 'default',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.disabled,
      transition: theme.transitions.create(['color', 'background']),

      ':hover, :focus': {
        color: theme.palette.text.secondary,
        background: theme.palette.action.hover,
      },

      ':disabled': {
        color: theme.palette.text.disabled,
        background: theme.palette.action.hover,
      },
    }),

    colorPrimary: ({ theme }) => ({
      color: theme.palette.primary.main,
      background: theme.palette.action.hover,

      ':hover, :focus': {
        color: theme.palette.text.secondary,
        background: theme.palette.action.disabledBackground,
      },
    }),
  },
  variants: [
    {
      props: { color: 'error' },
      style: ({ theme }) => ({
        color: theme.palette.error.main,
        ':hover,:focus': {
          color: theme.palette.error.main,
        },
      }),
    },
    {
      props: { color: 'info' },
      style: ({ theme }) => ({
        ':not(:disabled)': {
          color: theme.palette.info.main,
          ':hover,:focus': {
            color: theme.palette.info.main,
          },
        },
      }),
    },
  ],
} as Components<Theme>['MuiIconButton']
