import { Box, styled } from '@mui/material'

export const TimeBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#DCEDC8',
  color: '#7CB342',
  borderRadius: '6px',
  padding: '8px 12px',
  width: '92px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
