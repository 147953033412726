import { styled, Box } from "@mui/material"


export const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(1),
  alignItems: 'center',
  position: 'relative',
}))
