import { TextField } from "@mui/material"


export const Input = ({label, value, error, onChange}: InputProps) => (
  <TextField
    fullWidth
    multiline
    maxRows={5}
    variant="outlined"
    {...{label, error}}
    value={value || ''}
    onChange={evt => onChange && onChange(evt.target.value)}
  />
)
