export type Checkbox = {
  [key: string]: any
  title:
    | 'deleteSilenсe'
    | 'slice'
    | 'text'
    | 'addPunсtuation'
    | 'convertDigits'
    | 'addTranslate'
    | 'restoreCase'
  value:
    | 'Удаление тишины'
    | 'Нарезка по кускам длиной (сек)'
    | 'Отекстовка'
    | 'Пунктуация'
    | 'Перевод чисел из букв в цифры'
    | 'Перевод англоязычных названий на латиницу'
    | 'Перевод в верхний регистр первых букв имён и названий'
}


	export const textCheckboxes: Checkbox[] = [
  { title: 'addPunсtuation', value: 'Пунктуация' },
  {
    title: 'convertDigits',
    value: 'Перевод чисел из букв в цифры',
  },
  {
    title: 'addTranslate',
    value: 'Перевод англоязычных названий на латиницу',
  },
  {
    title: 'restoreCase',
    value: 'Перевод в верхний регистр первых букв имён и названий',
  },
]
