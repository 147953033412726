import loadable from '@loadable/component'

type Props = MarkupComponent & {
  isEdit: boolean
	component: MarkupComponentType
  onActionClick: (value: string) => void
}

const AsyncMarkupComponent = loadable(
  ({ component }: Props) => import(`control/${component.toLowerCase()}`),
  { cacheKey: ({ component }) => component }
)

export const ControlAsyncLoader = (args: Props) => (
  <AsyncMarkupComponent {...args} />
)
