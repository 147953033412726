import { Checkbox, CheckboxProps } from '@mui/material'

import { CheckedIcon, NotCheckedIcon } from './icons'


export const CustomCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      checkedIcon={<CheckedIcon />}
      icon={<NotCheckedIcon />}
      checked
      disabled
      {...props}
    />
  )
}
