import {
  Arrow,
  Checkbox,
  Container,
  IndexButton,
} from "../atoms"


type CompositeCheckboxProps = ArrowProps & IndexButtonProps & CheckboxProps

export const CompositeCheckbox = ({
  label, onChange, value, invert,
  index, color, onRemove, focus,
}: CompositeCheckboxProps) => (
  <Container>
    <Arrow focus={focus} />
    <IndexButton {...{color, index, onRemove}} />
    <Checkbox {...{onChange, value, invert, label}} />
  </Container>
)
