import { Order, User } from './type'


const getValue = (value: any) => {
	return (typeof value === 'undefined') ? 0 : value
}

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (getValue(b[orderBy]) < getValue(a[orderBy])) {
    return -1
  }
  if (getValue(b[orderBy]) > getValue(a[orderBy])) {
    return 1
  }
  return 0
}

export const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((
  a: { [key in Key]: number | string | boolean },
  b: { [key in Key]: number | string | boolean }
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
export const getIndeterminate = (users: User[]) => {
  return users.every((user) => Boolean(user.checked)) ? true : false
}
