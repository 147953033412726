import { Components, Theme } from '@mui/material'

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    project: true
    toolbar: true
    block: true
    property: true
  }
}

export default {
  variants: [
    {
      props: { variant: 'project' },
      style: ({ theme }) => ({
        '--hidden': 0,
        outline: 0,
        boxShadow: theme.shadows[1],
        display: 'flex',
        flexDirection: 'column',
        minHeight: 210,
        padding: theme.spacing(3),
        position: 'relative',

        ':hover,:focus,:focus-within': {
          '--hidden': 1,
          boxShadow: theme.shadows[2],
        },

        '[data-hidden]': {
          marginLeft: 'auto',
        },

        form: {
          display: 'contents',
        },
      }),
    },
    {
      props: { variant: 'toolbar' },
      style: ({ theme }) => ({
        minWidth: 64,
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
        borderRight: `1px solid ${theme.palette.divider}`,
      }),
    },
    {
      props: { variant: 'block' },
      style: ({ theme }) => ({
        flex: 1,
        boxShadow: theme.shadows[1],
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }),
    },
    {
      props: { variant: 'property' },
      style: ({ theme }) => ({
        '--hidden': 0,
        position: 'relative',
        background: 'var(--color)',
        transition: theme.transitions.create('background'),
        padding: theme.spacing(1.5),
        gridColumn: '1 / -1',
        display: 'grid',
        gridTemplate: `
          "c ." minmax(26px, auto)
          "p ." minmax(26px, auto) / auto 1fr 
        `,
        placeItems: 'center start',
        gap: theme.spacing(1, 3),

        ':hover, :focus-within': {
          '--hidden': 1,
        },

        '::before, ::after': {
          ...theme.typography.body2,
          color: theme.palette.text.secondary,
        },

        '::before': {
          content: '"Класс"',
          gridArea: 'c',
        },

        '::after': {
          content: '"Свойство"',
          gridArea: 'p',
        },

        '> [data-hidden]': {
          placeSelf: 'start end',
          gridArea: '1 / 1 / -1 / -1',
        },
      }),
    },
  ],
} as Components<Theme>['MuiPaper']
