import { createContext, useContext, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useFetch } from 'api'
import { FCC } from 'types'
import { Page } from './page'
import { Project } from './project'

//================ page projects

const PageContext = createContext({} as Page)

export const usePage = () => useContext(PageContext)

export const PageProvider: FCC = observer((props) => {
  const page = useRef(new Page()).current
  useFetch(page.fetch)

  return <PageContext.Provider value={page} {...props} />
})

//================ project

const ProjectContext = createContext({} as Project)

export const useProject = () => useContext(ProjectContext)

export const ProjectProvider: FCC<{ value: Project }> = observer((props) => {
  useFetch(props.value.fetch)

  return <ProjectContext.Provider {...props} />
})

//================ new project

export const NewProjectProvider: FCC = observer((props) => {
  const project = useRef(new Project()).current
  useFetch(project.fetch)

  return <ProjectContext.Provider value={project} {...props} />
})

//================ page project

export const ProjectPageProvider: FCC = observer((props) => {
  const page = useRef(new Project()).current
  useEffect(() => page.getProject(), [page])
  useFetch(page.fetch)

  return <ProjectContext.Provider value={page} {...props} />
})
