import { Components, Theme } from '@mui/material'

declare module '@mui/material/Skeleton' {
  interface SkeletonPropsVariantOverrides {
    button: true
    icon: true
    'button-class': true
    checkbox: true
  }
}

export default {
  styleOverrides: {
    root: {
      color: 'transparent',
    },
  },
  variants: [
    {
      props: { variant: 'button' },
      style: ({ theme }) => ({
        minWidth: 40,
        minHeight: 40,
        borderRadius: theme.shape.borderRadius,
      }),
    },
    {
      props: { variant: 'button-class' },
      style: ({ theme }) => ({
        minWidth: 32,
        minHeight: 32,
        borderRadius: theme.shape.borderRadius,
      }),
    },
    {
      props: { variant: 'icon' },
      style: () => ({
        minWidth: 30,
        minHeight: 30,
        borderRadius: '50%',
      }),
    },
    {
      props: { variant: 'checkbox' },
      style: ({ theme }) => ({
        minWidth: 24,
        minHeight: 24,
        margin: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
      }),
    },
  ],
} as Components<Theme>['MuiSkeleton']
