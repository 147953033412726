import { Button, ButtonGroup } from '@mui/material'
import { grey, teal } from '@mui/material/colors'


export type LanguageButtonsGroupProps = {
  onChange: (type: string, value: string) => void
  active: boolean
}

export const LanguageButtonsGroup = ({
  onChange,
  active,
}: LanguageButtonsGroupProps) => {
  return (
    <ButtonGroup
      disableElevation
      variant="contained"
      aria-label="Disabled elevation buttons"
    >
      <Button
        onClick={() => onChange('language', 'ru')}
        style={{
          backgroundColor: active ? teal[600] : grey[400],
          height: '30px',
        }}
      >
        Ru
      </Button>
      <Button
        onClick={() => onChange('language', 'en')}
        style={{
          backgroundColor: !active ? teal[600] : grey[400],
          height: '30px',
        }}
      >
        En
      </Button>
    </ButtonGroup>
  )
}
