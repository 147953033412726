import { observer } from 'mobx-react-lite'
import { Checkbox, Skeleton } from '@mui/material'
import {
  loading,
  enableSelection,
  toggleEnableSelection,
  category,
  toggleCategory,
} from 'features/settings-dataset'

export const EntitySelection = observer(() => {
  if (loading.get()) return <Skeleton variant={'checkbox'} />

  return (
    <Checkbox
      checked={enableSelection.get()}
      onChange={() => toggleEnableSelection()}
    />
  )
})

export const Categorization = observer(() => {
  if (loading.get()) return <Skeleton variant={'checkbox'} />

  return <Checkbox checked={category.get()} onChange={() => toggleCategory()} />
})
