import * as React from 'react'
import * as RRD from 'react-router-dom'

type Props = Partial<RRD.NavigateProps>

export const Replace: React.FC<Props> = (props) => (
  <RRD.Navigate
    replace={props.replace ?? true}
    to={props.to ?? ''}
    {...props}
  />
)
