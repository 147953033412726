import axios from 'axios'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { Stack, Typography } from '@mui/material'

import { DOMEN } from 'api'
import { Page, Icon } from 'shared'
import { token } from 'features/token'
import { useProject } from 'features/projects'
import { MarkupsTableWithPanel } from 'features/markups-table'
import { getTime } from './utils'

export const DatasetMarkupsTable = observer(() => {
  const project = useProject()
  const { datasetId } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [users, setUsers] = useState<any[]>([])
  const [datasetName, setDatasetName] = useState('')
  const [markups, setMarkups] = useState<any[]>([])

  const clear = () => {
    setUsers([])
    setMarkups([])
  }

  useEffect(() => {
    axios
      .get(`${DOMEN}/api/audio_markups/?record__dataset_id=${datasetId}&page_size=200`, {
        headers: token.headers,
      })
      .then((res) => {
        if (res.statusText === 'OK' && res?.data) {
          setMarkups(
            res.data.results?.map((markup: any) => ({
              ...markup,
              created_at: format(parseISO(markup.created_at), 'dd.MM.yyyy'),
              is_honeypot: markup?.df_match_ratio === 1 ? true : false,
            }))
          )
          res.data.results?.forEach((markup: any) => {
            axios
              .get(`${DOMEN}/${markup.task.split('/').slice(3).join('/')}`, {
                headers: token.headers,
              })
              .then((res) => {
                if (res.statusText === 'OK' && res?.data) {
                  setMarkups((prev) => [
                    ...prev.map((el) =>
                      el.task.split('/').slice(-2, -1)[0] === res.data.id
                        ? { ...el, time: getTime(res?.data) }
                        : el
                    ),
                  ])
                }
              })
          })

          axios
            .get(`${DOMEN}/api/users`, {
              headers: token.headers,
            })
            .then((res) => {
              if (res.statusText === 'OK' && res?.data) {
                setUsers(res.data.results)
                setLoaded(true)
              }
            })
        }
      })

    axios
      .get(`${DOMEN}/api/audio_dataset/${datasetId}`, {
        headers: token.headers,
      })
      .then((res) => setDatasetName(res.data.name))

    return () => clear()
  }, [datasetId])

  return (
    <Page>
      <Typography variant={'h1'} display={'flex'} alignItems={'center'} gap={1}>
        <Stack display={'contents'} color={'text.disabled'}>
          {project.name || '...'}
          <Icon icon={'arrow'} sx={{ transform: 'rotate(-90deg)' }} />
        </Stack>
        {datasetName || '...'}
      </Typography>
      <MarkupsTableWithPanel
        loaded={loaded}
        markups={markups.map((markup) => ({
          ...markup,
          user: users.filter((el) => el.url === markup.user)[0]?.username,
        }))}
      />
    </Page>
  )
})
