import { styled } from '@mui/system'
import { Button } from '@mui/material'


export const StyledGreenButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: 'rgba(15, 31, 72, 0.04)',
  ':hover, :active, :focus': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.paper,
  },
}))
