import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Paper, Skeleton, Stack } from '@mui/material'

import { useMarkup } from 'features/page-markup'
import { Select, utils } from 'markers/selectors'

import markupProccess from './showers/state'


export const DefaultCategory = observer(() => {
  const { categories, markupComponents, timer, isDisabled } = useMarkup()

  const class_values = utils.categoriesFromParent(categories, utils.classId(markupComponents))

  useEffect(() => {
    if (!markupProccess.defaultCategory && class_values.length) {
      markupProccess.setDefaultCategory(class_values[0])
    }
  }, [class_values, markupComponents, categories])

  if (isDisabled) { return <Stack gap={2}><Skeleton variant={'button'} /></Stack> }

  const onChange = (pk: Value) => markupProccess.setDefaultCategory(
    utils.getCategory(categories || [], Number(pk))
  )

  if (!timer) {
    return (
      <Stack gap={2}>
        <Skeleton variant={'button'} />
        <Skeleton variant={'button'} />
      </Stack>
    )
  }

  return (
    <Paper hidden={!class_values.length} elevation={3}>
      <Select
        values={class_values}
        onChange={onChange}
        value={markupProccess.defaultCategory?.pk}
      />
    </Paper>
  )
})
