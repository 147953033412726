import { Button } from '@mui/material'


export const IndexButton = ({ index, color, onRemove }: IndexButtonProps) => (
  <Button
    variant='class'
    sx={{ backgroundColor: color || '' }}
    children={`${String(index === undefined ? '' : index)?.slice(0,3) || ''}`}
    onClick={() => onRemove(index)}
  />
)
