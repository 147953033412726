import { observer } from 'mobx-react-lite'
import { Backdrop, CircularProgress } from '@mui/material'
import { usePage, useProject } from 'features/projects'

export const PageLoader = observer(() => {
  const page = usePage()

  return (
    <Backdrop data-loader open={!!page.fetch}>
      <CircularProgress />
    </Backdrop>
  )
})

export const ProjectLoader = observer(() => {
  const project = useProject()

  return (
    <Backdrop data-loader open={!!project.fetch}>
      <CircularProgress />
    </Backdrop>
  )
})
