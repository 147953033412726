import { MarkupComponents } from "types"


export const categoriesFromParent = (
  categories: Category[], parentId?: number
): Category[] => categories.filter(cat => Number(cat.parent?.pk) === Number(parentId))

export const classId = (
  markupComponents?: MarkupComponents
) => markupComponents?.class_values?.catalog_id

export const properties = (
  markupComponents?: MarkupComponents, key?: string
) => Object.entries(markupComponents?.classes[key || '']?.properties || {})

export const components = (properties?: Properties) => Object.values(properties || {})

export const component = (type: string) => Object.values(properties || {})

export const getCategory = (
  categories: Category[], pk: Category['pk']
) =>categories.find(cat => cat.pk === pk)
