import { observer } from 'mobx-react-lite'
import { Button, IconButton } from '@mui/material'
import { Icon } from 'shared'
import { useProject, usePage } from 'features/projects'

export const ButtonNewProject = () => {
  const page = usePage()

  return (
    <Button
      children={'Новый проект'}
      variant={'contained'}
      onClick={() => page.onToggleNewProject()}
      sx={{ alignSelf: 'end' }}
    />
  )
}

export const ButtonClose = () => {
  const project = useProject()
  const page = usePage()

  return (
    <IconButton
      children={<Icon icon={'close'} />}
      sx={{ ml: 'auto' }}
      onClick={() =>
        project.id ? project.setView(null) : page.onToggleNewProject()
      }
    />
  )
}

export const ButtonRemove = () => {
  const project = useProject()

  if (!project.id) return null

  return (
    <IconButton
      children={<Icon icon={'remove'} />}
      onClick={() => project.setView('remove')}
    />
  )
}

export const ButtonEdit = () => {
  const project = useProject()

  return (
    <IconButton
      data-hidden
      children={<Icon icon={'edit'} />}
      onClick={() => project.setView('edit')}
    />
  )
}

export const ButtonSubmit = observer(() => {
  const project = useProject()

  return (
    <IconButton
      type={'submit'}
      color={'primary'}
      children={<Icon icon={'done'} />}
      onClick={() => (project.id ? project.onUpdate() : project.onCreate())}
      disabled={project.isDisabled}
    />
  )
})
