import { Skeleton } from '@mui/material'
import { createCell, createTemplate, mergeSX } from 'utils'
import * as Atom from './grid-atoms'

const head = [
  createCell(<Atom.ChboxHead />),
  createCell(null, { width: '1fr', placeContent: 'center' }),
  createCell('Тип', { width: '1fr', placeContent: 'center' }),
  createCell('Название', { width: '10fr' }),
  createCell('Статус', { width: '3fr', placeItems: 'center' }),
  createCell('Всего', { width: '2fr', placeContent: 'center end' }),
  createCell('Размечено', { width: '2fr', placeContent: 'center end' }),
  createCell('Пропущено', { width: '2fr', placeContent: 'center end' }),
  createCell('Дата создания', { width: '2fr', placeContent: 'center' }),
  createCell(null),
]

const template = createTemplate(head)

const row = [
  createCell(<Atom.ChboxRow />),
  createCell(<Atom.Link />),
  createCell(<Atom.Type />),
  createCell(<Atom.Name />),
  createCell(<Atom.State />),
  createCell(<Atom.Records />),
  createCell(<Atom.Markups />),
  createCell(<Atom.Skiped />),
  createCell(<Atom.Date />),
  createCell(<Atom.Remove />),
]

const skeleton = [
  createCell(<Skeleton variant={'checkbox'} />),
  createCell(<Skeleton variant={'icon'} />),
  createCell(<Skeleton variant={'icon'} />),
  createCell(<Skeleton />, { placeItems: 'center stretch' }),
  createCell(<Skeleton variant={'button'} width={120} />),
  createCell(<Skeleton width={'3ch'} />),
  createCell(<Skeleton width={'3ch'} />),
  createCell(<Skeleton width={'3ch'} />),
  createCell(<Skeleton width={'5ch'} />),
  createCell(<Skeleton variant={'button'} />),
]

mergeSX(head, row)
mergeSX(head, skeleton)

export { head as headCells, template, row as rowCells, skeleton as skeletons }
