import { makeAutoObservable } from 'mobx'

export class TimerState {
  constructor(
    private expired: null | Date = null,
    private issued: null | Date = null,
    private current = new Date()
  ) {
    makeAutoObservable(this)
  }

  tick = () => {
    this.current = new Date()
  }

  clear = () => {
    this.expired = null
    this.issued = null
  }

  get progress() {
    if (!this.expired || !this.issued) return 0

    const exp = this.expired.getTime()
    const iss = this.issued.getTime()
    const now = this.current.getTime()

    return 100 - ((exp - now) / (exp - iss)) * 100
  }

  get timeStr() {
    if (!this.expired) return null

    const date = new Date(this.expired.getTime() - this.current.getTime())

    return new Date(
      0,
      0,
      0,
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    ).toLocaleTimeString()
  }

  get isOver() {
    return this.progress > 100
  }
}
