import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Stack } from '@mui/material'
import axios from 'axios'

import { Page, PageTitleProject, Grid, ButtonMarkup } from 'shared'
import { NewDatasetProvider, usePage } from 'features/datasets'
import { useProject } from 'features/projects'

import { AudioSet, ModalAudio } from './modal-audio/organisms/modal-audio'
import { ModalError } from './modal-audio/organisms/modal-error'
import { Loader } from './modal-audio/atoms/loader'
import errors from './modal-audio/errors.json'
import { template } from './grid-cells'
import { GridHead } from './grid-head'
import { GridBody } from './grid-body'
import { NewFile } from './new-file'


export const Datasets = observer(() => {
  const page = usePage()
  const project = useProject()
	const [message, setMessage] = useState('')
	const [isLoader, setIsLoader] = useState(false)
	const [files, setFiles] = useState<File[]>([])
  const [isAudioModal, setIsAudioModal] = useState(false)

  const formData = new FormData()

  const getMessage = (message: string) => {
    const findedError = errors.messages.find((el) => el.error.toLowerCase() === message.toLowerCase() )
    return findedError ? findedError.message : 'Неизвестная ошибка'
  }

  const handle = (newAudio: AudioSet) => {
    setIsAudioModal(false)
    if (
      files.length &&
      !(newAudio.deleteSilenсe || newAudio.text || newAudio.slice)
    ) {
    } else if (files.length) {
      if (newAudio.slice)
        formData.append(
          'split_in_seconds',
          newAudio.seconds ? `${newAudio.seconds}` : '0'
        )
      formData.append('proj_id', `${project.id}`)
      formData.append('language', newAudio.language)
      formData.append('translate', `${newAudio.addTranslate}`)
      formData.append('no_silence', `${newAudio.deleteSilenсe}`)
      formData.append('dataset_name', `${newAudio.datasetName}`)
      formData.append('convert_digits', `${newAudio.convertDigits}`)
      formData.append('use_punctuation', `${newAudio.addPunсtuation}`)
      if (newAudio.text) formData.append('transcription', `${newAudio.text}`)

      setIsLoader(true)
      axios
        .post(
          'https://marker-segmentation.nanosemantics.ai/preprocessing/',
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
        .then((response) => {
          if (response.status === 200) {
            axios
              .get(
                `https://marker-segmentation.nanosemantics.ai/preprocessing/result/${response.data}`
              )
              .then((res) => {
                setIsLoader(false)
                window.location.reload()
              })
              .catch((err) => {
                setIsLoader(false)
                setMessage(getMessage(err?.response?.data?.message))
              })
          } else {
            setIsLoader(false)
            setMessage(getMessage(response.data.message))
          }
        })
        .catch((err) => {
          setIsLoader(false)
          setMessage(getMessage(err?.response?.data?.message))
        })
    }
  }

  useEffect(() => {
    page.getList()
  }, [])

  useEffect(() => {
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('file', files[i])
      }
    }
  }, [files])

  return (
    <Page>
      <PageTitleProject />
      <Stack direction={'row'} gap={1} justifyContent={'end'}>
        <ButtonMarkup />
        <NewDatasetProvider>
          <NewFile setIsAudioModal={setIsAudioModal} setFiles={setFiles} />
        </NewDatasetProvider>
      </Stack>
      <Grid sx={{ '--template': template }}>
        <GridHead />
        <NewDatasetProvider>
         {isAudioModal && <ModalAudio
            isModal={isAudioModal}
            setIsModal={setIsAudioModal}
            handle={handle}
            files={files}
          />}
        </NewDatasetProvider>
        {message && (
          <ModalError
            message={message}
            onCloseModalError={() => setMessage('')}
          />
        )}
        <Loader isLoader={isLoader} />
        <GridBody />
      </Grid>
    </Page>
  )
})
