import {
  MenuItem,
  InputLabel,
  FormControl,
  Select as MuiSelect,
} from "@mui/material"


export const Select = ({ label, onChange, value, values }: SelectPropsSelectors) => (
  <FormControl fullWidth size="small">
    <InputLabel id={`id-${label}`}>{label}</InputLabel>
    <MuiSelect
      labelId={`id-${label}`}
      label={label}
      onChange={evt => onChange && onChange(Number(evt.target.value))}
      value={value || ''}
    >
      {values.sort((a, b) => ('' + a.name).localeCompare(b.name)).map(val => (
        <MenuItem key={`${val.pk}`} value={`${val.pk}`}>
          {val.name}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
)
