import { observer } from 'mobx-react-lite'
import { Skeleton, Stack, Typography } from '@mui/material'
import { loading, json } from 'features/settings-dataset'

export const Json = observer(() => {
  if (loading.get())
    return (
      <Stack m={2}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Stack>
    )

  return (
    <Typography
      component={'pre'}
      m={2}
      overflow={'auto'}
      color={'text.secondary'}
      whiteSpace={'break-spaces'}
      children={json.get()}
    />
  )
})
