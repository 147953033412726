import {
  styled,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'


export type MarkupsTableHeaderProps = {
  orderBy: string
  order: 'asc' | 'desc'
  onSort: (property: string) => void
}

const StyledTableCell = styled(TableCell)({
  fontSize: '14px',
  color: 'text.disabled',
})

export const MarkupsTableHeader = ({
  order,
  onSort,
  orderBy,
}: MarkupsTableHeaderProps) => (
  <TableHead>
    <TableRow>
      <StyledTableCell sx={{paddingLeft: '14px'}}>
        <TableSortLabel
          children={'ID'}
          active={orderBy === 'id'}
          onClick={() => onSort('id')}
          IconComponent={ArrowDropDown}
          direction={orderBy === 'id' ? order : 'asc'}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <TableSortLabel
          active={orderBy === 'user'}
          IconComponent={ArrowDropDown}
          children={'Пользователь'}
          onClick={() => onSort('user')}
          direction={orderBy === 'user' ? order : 'asc'}
        />
      </StyledTableCell>
      <StyledTableCell align="left">
        <TableSortLabel
          children={'Тип'}
          IconComponent={ArrowDropDown}
          active={orderBy === 'is_honeypot'}
          onClick={() => onSort('is_honeypot')}
          direction={orderBy === 'is_honeypot' ? order : 'asc'}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <TableSortLabel
          children={'Пропущена'}
          IconComponent={ArrowDropDown}
          active={orderBy === 'is_skipped'}
          onClick={() => onSort('is_skipped')}
          direction={orderBy === 'is_skipped' ? order : 'asc'}
        />
      </StyledTableCell>
      <StyledTableCell align="center">
        <TableSortLabel
          IconComponent={ArrowDropDown}
          active={orderBy === 'df_match_ratio'}
          onClick={() => onSort('df_match_ratio')}
          direction={orderBy === 'df_match_ratio' ? order : 'asc'}
          children={'Коэффициент совпадения с ханипотной разметкой'}
        />
      </StyledTableCell>
			<StyledTableCell align="center">
        <TableSortLabel
          children={'Время разметки'}
          IconComponent={ArrowDropDown}
          active={orderBy === 'time'}
          onClick={() => onSort('time')}
          direction={orderBy === 'time' ? order : 'asc'}
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        <TableSortLabel
          children={'Дата создания'}
          IconComponent={ArrowDropDown}
          active={orderBy === 'created_at'}
          onClick={() => onSort('created_at')}
          direction={orderBy === 'created_at' ? order : 'asc'}
        />
      </StyledTableCell>
    </TableRow>
  </TableHead>
)
