import { Box } from '@mui/material'

import { StyledGreenButton } from '../../styled-green-button'


type ReasonButtonsProps = {
  onChangeReason: (value: string) => void
}

export const ReasonButtons = ({ onChangeReason }: ReasonButtonsProps) => (
  <Box>
    <StyledGreenButton
      variant={'contained'}
      sx={{ mb: 1 }}
      onClick={() => onChangeReason('Недостаточная проработка')}
    >
      Недостаточная проработка
    </StyledGreenButton>
    <StyledGreenButton
      variant={'contained'}
      sx={{ mb: 1 }}
      onClick={() => onChangeReason('Несоблюдение инструкции')}
    >
      Несоблюдение инструкции
    </StyledGreenButton>
    <StyledGreenButton
      variant={'contained'}
      sx={{ mb: 1 }}
      onClick={() => onChangeReason('Ошибка нового вида')}
    >
      Ошибка нового вида
    </StyledGreenButton>
  </Box>
)
