import { TextField } from "@mui/material"
import { NumericFormat } from "react-number-format"


type Props = {
  max: number
  value: number
  disabled?: boolean
  isFactor: boolean
  onChange: (value: number) => void
}

export const FactorInput = ({
  max, isFactor, value, disabled, onChange,
}: Props) => (
  <NumericFormat
    value={value}
    disabled={disabled}
    valueIsNumericString
    allowNegative={false}
    customInput={TextField}
    suffix={isFactor ? ' %' : ' шт'}
    decimalScale={isFactor ? undefined : 0}
    label={isFactor ? 'Проценты' : 'Количество'}
    onValueChange={({ floatValue=0 }) => onChange(floatValue)}
    isAllowed={values => Number(values.value) <= (isFactor ? 100 : max)}
  />
)
