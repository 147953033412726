import { projectType } from "app/constants";


export const getTypeColor = (type: string) => {
  switch (type) {
    case projectType.TEXT: return 'success';
    case projectType.AUDIO: return 'warning';
    case projectType.IMAGE: return 'secondary';
    case projectType.VIDEO: return 'info';
    default: return 'standard';
  }
}

export const getTypeColorHex = (type: string) => {
  switch (type) {
    case projectType.TEXT: return '#7cb342';
    case projectType.AUDIO: return '#ff9800';
    case projectType.IMAGE: return '#673ab7';
    case projectType.VIDEO: return '#1976d2';
    default: return '#0000008a';
  }
}

export const getTypeHumanName = (type: string) => {
  switch (type) {
    case projectType.TEXT: return 'Текст';
    case projectType.AUDIO: return 'Аудио';
    case projectType.IMAGE: return 'Изображения';
    case projectType.VIDEO: return 'Видео';
    default: return '#0000008a';
  }
}
