import * as Mobx from 'mobx-react-lite'
import * as Mui from '@mui/material'
import { useKeycloak } from '@react-keycloak/web';

export const Avatar = Mobx.observer(() => {
  const { keycloak } = useKeycloak();
  const userName = keycloak.tokenParsed?.preferred_username
  
  return (
      <Mui.Avatar
        children={userName[0].toUpperCase() || 'U'}
        onClick={() => keycloak.logout()}
        sx={{ mt: 'auto' }}
      />
   )
})
