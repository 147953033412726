import * as Mui from '@mui/material'
import { renderIcon } from '../icon'
import { observer } from 'mobx-react-lite'

type Props = Pick<Mui.ButtonProps, 'disabled' | 'onClick' | 'sx'>

export interface DatasetStatusProps extends Props {
  status: number
}

const play = renderIcon('PLAY', { color: 'primary' })
const pause = renderIcon('PAUSE', { color: 'disabled' })
const icons = [null, null, play, pause, null, play]
const texts = [
  'инициализация',
  'подготовка',
  'пауза',
  'разметка',
  'завершён',
  'выгружен',
]

const Root = Mui.styled(Mui.ButtonBase)(({ theme }) => ({
  ...theme.typography.body1,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  color: theme.palette.text.disabled,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,

  ':hover,:focus': {
    background: theme.palette.action.hover,
  },

  '::before': {
    content: 'attr(data-status)',
  },

  '&[data-status="пауза"]': {
    color: theme.palette.text.primary,
  },

  '&[data-status="разметка"]': {
    color: theme.palette.info.main,
  },

  '&[data-status="завершён"]': {
    color: theme.palette.primary.main,
    pointerEvents: 'none',
  },

  '&[data-status="подготовка"]': {
    pointerEvents: 'none',
  },
}))

export const Status = observer<DatasetStatusProps>(({ status, ...props }) => (
  <Root {...props} data-status={texts[status]}>
    {icons[status]}
  </Root>
))
