import { observer } from 'mobx-react-lite'
import { useKeycloak } from '@react-keycloak/web'

import { token } from 'features/token'
import { ReactComponent as Logo } from 'assets/logo.svg'

import * as Atom from './atoms'
import { Avatar } from './avatar'
import * as Routes from './routes'
import { Navigation } from './navigation'
import PrivateRoute from './PrivateRoutes'
import { NotAuthRedirect } from './routes'


export const App = observer(() => {
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated === undefined) return null
  console.log('keycloak', keycloak)

  if (keycloak.token) {
    token.set(keycloak.token)
  }

  return !keycloak.authenticated ? (
    <NotAuthRedirect />
  ) : (
    <Atom.Template>
      <Atom.Toolbar>
        <Logo />
        <Navigation />
        <Avatar />
      </Atom.Toolbar>
        <PrivateRoute>
          <Routes.Auth />
        </PrivateRoute>
    </Atom.Template>
  )
})
