import { styled } from '@mui/material/styles'


export const NotCheckedIcon = styled('span')(({ theme }) => ({
  borderRadius: 8,
  width: 24,
  height: 24,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#B2DFDB',
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#B2DFDB',
  },
}))
