import { makeAutoObservable, toJS } from "mobx"


class MarkupProccess {
  selections: SelectionT[] = []
  defaultSelection: Category['pk'] = 0
  defaultCategory?: Category
  rootSelection: { [key: string]: Value } | {} = {}

  constructor() {
      makeAutoObservable(this)
  }

  setDefaultSelection(pk: Category['pk']) {
    this.defaultSelection = pk
  }

  setDefaultCategory(category?: Category) {
    if (category) this.defaultCategory = category
  }

  setRootSelection(key: string, property: Value) {
    this.rootSelection = {
      ...this.rootSelection,
      [key]: property,
    }
  }
  setRootSelections(selections: { [key: string]: Value } | {}) {
    this.rootSelection = selections
  }

  setCommonSelections(selections: SelectionT[]) {
    this.selections = selections
  }

  modifySelection(select: SelectT) {
    if (this.defaultCategory) {
      const modifySelect = this.selections.find(sel => sel.selection.index === select.index)
      if (modifySelect) {
        modifySelect.selection = select
      } else {
        this.selections.push({
          uuid: new Date().getTime(),
          category: this.defaultCategory,
          selection: {
            ...select,
            color: this.defaultCategory.color,
          },
          properties: {},
        })
				this.selections.forEach((el, ind) => (el.selection.index = ind + 1))
      }
    }
  }

	modifySelectionMagic(selects: SelectT[]) {
		if (this.defaultCategory) {
		selects.forEach((select) => {
			this.selections.push({
				uuid: Math.random(),
				category: this.defaultCategory,
				selection: {
					...select,
					color: this.defaultCategory?.color,
				},
				properties: {},
			})
		})
		this.selections.forEach((el, ind) => (el.selection.index = ind + 1))
    }
	}

  onSelectionChange(element: SelectionT, type: string, categories: Category[], value?: Value) {
    if (type === 'REMOVE') {
      this.selections = [
        ...this.selections.filter(sel => sel.uuid !== element.uuid)
      ]
			this.selections.forEach((el, ind) => (el.selection.index = ind + 1))
    } else if (type === 'COMMON') {
      const selection = this.selections.find(sel => sel.uuid === element.uuid)
      const category = categories.find(cat => cat.pk === Number(value))
      if (selection && category) {
        selection.category = category
        selection.selection = {
          ...selection.selection,
          color: category.color,
        }
      }
    } else {
      const selection = this.selections.find(sel => sel.uuid === element.uuid)
      if (selection && type) {
        selection.properties = {
          ...selection.properties,
          [type]: value,
        }
      }
    }
  }

  getEntities() {
    const entities = [
      {
        class: { "class_id": "ROOT" },
        selection: {},
        properties: toJS(this.rootSelection)
      },
      ...toJS(this.selections)
    ]
    return entities
  }

  onRemove(index: number | string) {
    this.selections = this.selections.filter(sel => sel.selection.index !== index)
		this.selections.forEach((el, ind) => (el.selection.index = ind + 1))
  }

  clear() {
    this.selections = []
    this.defaultSelection = 0
    this.defaultCategory = undefined
    this.rootSelection = {}
  }
}

export default new MarkupProccess()
