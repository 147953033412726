import { toJS } from 'mobx'
import { Buffer } from 'buffer'
import { TaskByProject } from 'types'
import { DOMEN } from 'api'


type T = TaskByProject['taskByProject']

export const getTaskType = (task: T) =>
  task?.dataset.config.display_component?.type

export const getMarkupComponent = (task: T) =>
  task?.dataset.config.markup_components

export const getCatalogIds = (task: T): number[] => {
  const markupComponents = task?.dataset.config.markup_components
  const masterCatalog = markupComponents?.class_values?.catalog_id

  const classes = Object.values(markupComponents?.classes || {})
  const catalogs = []

  // пихаем обязательный каталог
  if (masterCatalog) {
    catalogs.push(masterCatalog)
  }

  // проходимся по зависимым каталогам (смотри схему)
  classes.map(cl => Object.values(cl.properties).forEach(c => {
    const catalogId = c?.settings?.values.catalog_id
    if (catalogId) {
      catalogs.push(catalogId)
    }
  }))

  return catalogs
}

export const getClassCatalogId = (task: T) =>
  task?.dataset.config.markup_components.class_values?.catalog_id

export const getTaskText = (task: T) => task?.data.edges[0].node.data.text

export const getDataFromTask = (task: T): string => {
  const type = task?.dataset.config.display_component.data.type
  const value = task?.dataset.config.display_component.data.value || 'text'

  switch (type) {
    case 'FILE_OBJECT': {
      const idBase64 = task?.data.edges[0].node.id
      const pk = Buffer.from(String(idBase64), 'base64').toString().slice(11)
      return `${DOMEN}/media/${pk}/`
    }
    case 'JSON_PATH': {
      return task?.data.edges[0].node.data[value] || ''
    }
    default: return ''
  }
}
