import { MenuItem, FormControl, Select as MuiSelect } from '@mui/material'

import { BootstrapInput } from './bootstrap-input'


export type SelectProps = {
  value: number
  roles: {
    pk: number
		name: string
  }[]
  isGrey: boolean
	onChange: OnChange
}

export const Select = ({ onChange, value, roles, isGrey }: SelectProps) => (
  <FormControl size="small">
    <MuiSelect
      input={<BootstrapInput />}
      onChange={(evt) => onChange(evt.target.value)}
      value={value || 0}
      sx={{
        color: isGrey ? 'rgba(15, 31, 72, 0.6)' : '#0F1F48',
      }}
    >
      {roles.map((role) => (
        <MenuItem key={role.pk} value={role.pk}>
          {role.name}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
)
