import { MenuItem, MenuItemProps } from '@mui/material'

export const renderMenuItems = (
  arr: (string | number | MenuItemProps)[],
  labels: string[] = []
) =>
  arr.map((item, i) =>
    typeof item === 'string' || typeof item === 'number' ? (
      <MenuItem key={String(item)} value={item} children={labels[i] ?? item} />
    ) : (
      <MenuItem
        {...item}
        key={String(item.value)}
        value={item.value}
        children={item.children ?? item.value}
      />
    )
  )
