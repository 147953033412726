import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { App } from 'app'
import { ThemeProvider } from 'theme'
import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak from './Keycloak'


const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider>
      <ReactKeycloakProvider authClient={keycloak}>
        <App />
      </ReactKeycloakProvider>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
