import { observer } from 'mobx-react-lite'
import * as Mui from '@mui/material'
import * as Shared from 'shared'
import { useMarkup } from 'features/page-markup'
import { TimerProvider, useTimer } from 'features/timer'

const Template = Mui.styled('div')(({ theme }) => ({
  minHeight: 40,
  display: 'grid',
  gap: theme.spacing(1),
  alignContent: 'space-between',
  placeItems: 'center',
  gridTemplate: `
    "i ." auto
    "i ." auto / auto 1fr
  `,
}))

const Skeleton = observer(() => (
  <Template>
    <Mui.Skeleton sx={{ gridArea: 'i' }} variant={'icon'} />
    <Mui.Skeleton width={100} />
    <Mui.Skeleton sx={{ placeSelf: 'stretch' }} />
  </Template>
))

const Type = observer(() => (
  <Shared.Icon
    icon={useMarkup().type}
    data-type={useMarkup().type}
    sx={{ gridArea: 'i' }}
  />
))

const TimeStr = observer(() => (
  <Mui.Typography
    children={useTimer().timeStr}
    textAlign={'center'}
    color={'text.secondary'}
  />
))

const Progress = observer(() => (
  <Mui.LinearProgress
    data-timer
    variant={'determinate'}
    value={useTimer().progress}
    sx={{ placeSelf: 'stretch' }}
  />
))

export const Timer = observer(() => {
  const { timer } = useMarkup()

  if (!timer || timer.isOver) return <Skeleton />

  return (
    <TimerProvider value={timer}>
      <Template>
        <Type />
        <TimeStr />
        <Progress />
      </Template>
    </TimerProvider>
  )
})
