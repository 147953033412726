import { observer } from 'mobx-react-lite'
import { useState, MouseEvent } from 'react'
import { CloudUpload, Download } from '@mui/icons-material'
import {
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material'

import { usePage } from 'features/datasets'
import { GridRow, Cells, GridCell, Icon } from 'shared'
import { CreateHoneypotDataset } from 'features/smart-buttons/create-honeypot'

import { headCells } from './grid-cells'
import { CountSelected } from './grid-atoms'


const Selected = observer(() => {
  const page = usePage()

  const [anchorElHoneypot, setAnchorElHoneypot] =
    useState<null | HTMLElement>(null)
  const [anchorElDownload, setAnchorElDownload] =
    useState<null | HTMLElement>(null)

  const [isConsensus, setIsConsensus] = useState(false)

  const handleOpen = (
    event: MouseEvent<HTMLButtonElement>,
    honeypot?: boolean
  ) =>
    honeypot
      ? setAnchorElHoneypot(event.currentTarget)
      : setAnchorElDownload(event.currentTarget)
  const handleClose = (honeypot?: boolean) =>
    honeypot ? setAnchorElHoneypot(null) : setAnchorElDownload(null)

  const allOrNot =
    page.allDatasets?.length === page.selected.length
      ? ' все'
      : page.selected.length > 1
      ? ' выделенные'
      : ''

  return (
    <GridRow data-head>
      <Cells cells={headCells.slice(0, 1)} />
      <GridCell
        sx={{
          gridColumn: '2 / -1',
          gap: 2,
          placeContent: 'center start',
        }}
      >
        <Typography>Выбрано:</Typography>
        <Typography width={'5ch'}>
          <CountSelected />
        </Typography>
        <Button
          children={'Скачать разметку'}
          startIcon={<Download />}
          onClick={(evt) => handleOpen(evt)}
        />
        <Menu
          anchorEl={anchorElDownload}
          open={Boolean(anchorElDownload)}
          onClose={() => handleClose()}
        >
          <MenuItem>
            <FormControlLabel
              label={'Приоритетная разметка'}
              control={
                <Switch
                  checked={isConsensus}
                  onChange={() => setIsConsensus((prev) => !prev)}
                />
              }
            />
          </MenuItem>
          <MenuItem onClick={() => page.downloadMarkup('zip', isConsensus)}>
            <Download />
            Скачать разметку в формате .zip
          </MenuItem>
          <MenuItem onClick={() => page.downloadMarkup('mnf', isConsensus)}>
            <Download />
            Скачать разметку в формате .mnf
          </MenuItem>
          {page.allDatasets[0].type === 'IMAGE' && (
            <>
              <MenuItem
                onClick={() => page.downloadMarkup('coco', isConsensus)}
              >
                <Download />
                Скачать разметку в формате COCO
              </MenuItem>
              <MenuItem
                onClick={() => page.downloadMarkup('yolo', isConsensus)}
              >
                <Download />
                Скачать разметку в формате YOLO
              </MenuItem>
            </>
          )}
        </Menu>
        <Button
          children={'Создать ханипоты'}
          startIcon={<CloudUpload />}
          onClick={(evt) => handleOpen(evt, true)}
          disabled={!page.noHoneypotSelected.length}
        />
        <Menu
          anchorEl={anchorElHoneypot}
          open={Boolean(anchorElHoneypot)}
          onClose={() => handleClose(true)}
        >
          {page.noHoneypotSelected.map((dataset) => (
            <CreateHoneypotDataset
              key={dataset.id}
              title={dataset.name}
              onSubmit={(value, isFactor) => {
                page.createHoneypotDataset(
                  dataset,
                  `${dataset.name}_Honeypot`,
                  value,
                  isFactor
                )
                handleClose(true)
              }}
              max={dataset.total.records || 0}
            />
          ))}
        </Menu>
        <Button
          children={'Перенести в hadoop'}
          startIcon={<CloudUpload />}
          onClick={() => page.exportToHadoop()}
        />
        <Button
          children={'Удалить' + allOrNot}
          startIcon={<Icon icon={'remove'} />}
          onClick={() => page.onRemoveAll()}
        />
      </GridCell>
    </GridRow>
  )
})

export const GridHead = observer(() => {
  const page = usePage()

  if (page.hasSelected) return <Selected />

  return (
    <GridRow data-head>
      <Cells cells={headCells} />
    </GridRow>
  )
})
