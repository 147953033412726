import { makeAutoObservable, observable } from 'mobx'

export class SelectionsState {
  key: number
  items = observable.array<ClassState>()
  isOver = false

  constructor() {
    this.key = this.items.length

    makeAutoObservable(this)
  }

  onOver = () => {
    this.isOver = true
  }

  onOut = () => {
    this.isOver = false
  }

  resetKey = () => {
    this.key = 0
  }

  resetItems = () => {
    this.items.clear()
  }

  onPush = (anchor: number, focus: number) => {
    this.key++
    this.items.push(new ClassState(this, this.key, anchor, focus))
  }
}

export class ClassState {
  isOver = false

  constructor(
    private root: SelectionsState,
    public key: number,
    public ancor: number,
    public focus: number
  ) {
    makeAutoObservable(this)
  }

  setIsOver = (isOver: boolean) => {
    this.isOver = isOver
  }

  onOver = () => {
    this.isOver = true
  }
  onOut = () => {
    this.isOver = false
  }

  onRemove = () => {
    this.root.items.remove(this)
  }

  get start() {
    return this.ancor < this.focus ? this.ancor : this.focus
  }

  get end() {
    return this.ancor < this.focus ? this.focus : this.ancor
  }
}
