import { createContext, useContext, useRef } from 'react'
import { FCC } from 'types'
import { useFetch } from 'api'
import { FormLoginState } from './state'
import { observer } from 'mobx-react-lite'

const Context = createContext({} as FormLoginState)

export const useFormLogin = () => useContext(Context)

export const FormLoginProvider: FCC = observer((props) => {
  const form = useRef(new FormLoginState()).current
  useFetch(form.fetch)

  return <Context.Provider value={form} children={props.children} />
})
