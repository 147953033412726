import { matchPath } from 'react-router-dom'

export function getParams(pattern: string) {
  const match = matchPath(pattern, window.location.pathname)

  return match?.params ?? {}
}

export const getProjectId = () => getParams('project/:id/*').id
export const getDatasetId = () => getParams('project/:project/datasets/:id').id
