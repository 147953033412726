import {
  Arrow,
  Select,
  Container,
  IndexButton,
} from "../atoms"


type CompositeSelectProps = ArrowProps & IndexButtonProps & SelectPropsSelectors

export const CompositeSelect = ({
  label, onChange, value, values,
  index, color, onRemove, focus,
}: CompositeSelectProps) => (
  <Container>
    <Arrow focus={focus} />
    <IndexButton {...{color, index, onRemove}} />
    <Select {...{label, onChange, value, values}} />
  </Container>
)
