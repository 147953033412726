import { observer } from 'mobx-react-lite'
import { Skeleton, Typography } from '@mui/material'
import { useCatalog } from 'features/catalogs'

export const Id = observer(() => {
  const catalog = useCatalog()

  if (catalog.fetch) return <Skeleton width={'3ch'} />

  if (!catalog.id) return <></>

  return <Typography children={catalog.id} color={'text.secondary'} />
})
