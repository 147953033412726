import { reaction, runInAction, action, entries, observable } from 'mobx'
import { Prop } from './prop'

import {
  catalogId,
  category,
  classes,
  dataset,
  dataType,
  dataValue,
  enableSelection,
} from './store'

reaction(
  () => dataset.get()?.config,
  (config) => {
    if (config) {
      const { markup_components: mc, display_component: dc } = config
      runInAction(() => {
        dataType.set(dc.data.type)
        dataValue.set(dc.data.value)
        enableSelection.set(dc.enable_entity_selection)
        catalogId.set(String(mc.class_values?.catalog_id ?? ''))
      })
    }
  }
)

reaction(
  () => dataset.get()?.config.markup_components.classes,
  (obj) => {
    if (obj) {
      classes.clear()

      entries(obj).forEach(([c, { properties }]) => {
        classes.set(c, observable.map())
        entries(properties).forEach(([p, prop]) => {
          classes.get(c)?.set(p, new Prop(c, p, prop))
        })
      })
    }
  }
)

reaction(
  () => classes.has('ROOT'),
  action((bool) => category.set(bool))
)
