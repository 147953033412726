import { Box, Stack, Typography } from '@mui/material'

import { ReasonButtons } from './molecules'
import { StyledRedButton } from '../styled-red-button'
import { ActionSelect, ReasonTextField } from './atoms'
import { ActionSelectProps } from './atoms/action-select'
import { StyledGreenButton } from '../styled-green-button'
import { ReasonTextFieldProps } from './atoms/reason-textfield'

type ReasonsPanelProps = ReasonTextFieldProps &
  ActionSelectProps & {
    onControlActionClick: (value: string) => void
  }

export const ReasonsPanel = ({
  value,
  values,
  reason,
  onChangeReason,
  onChangeAction,
  onControlActionClick,
}: ReasonsPanelProps) => {
  return (
    <Stack px={3} gap={2}>
      <Box>
        <Typography variant={'h2'}>Причина отклонения</Typography>
        <Typography color={'text.secondary'}>
          Оставьте комментарий разметчику
        </Typography>
      </Box>
      <ReasonButtons onChangeReason={onChangeReason} />
      <ReasonTextField reason={reason} onChangeReason={onChangeReason} />
      <ActionSelect
        value={value}
        values={values}
        onChangeAction={onChangeAction}
      />
      <Stack direction={'row'} gap={1} justifyContent={'center'}>
        <StyledGreenButton
          children={'Отменить'}
          variant={'contained'}
          onClick={() => onControlActionClick('cancel')}
        />
        <StyledRedButton
          children={'Отправить'}
          variant={'contained'}
          onClick={() => onControlActionClick('send')}
        />
      </Stack>
    </Stack>
  )
}
