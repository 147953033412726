import { ReactNode, CSSProperties } from 'react'
import { SxProps, Theme } from '@mui/material'
import * as uuid from 'uuid'
import { GridCellProps as Cell, WithKey } from 'types'

export function createCells(
  head: ReactNode,
  body: ReactNode,
  ...sx: SxProps[]
) {
  return {
    head: {
      key: uuid.v4(),
      children: head,
      sx: sx[0],
    } as WithKey<Cell>,
    body: {
      key: uuid.v4(),
      children: body,
      sx: { ...sx[0], ...sx[1] },
    } as WithKey<Cell>,
  }
}

export function getTemplate(arr: ReturnType<typeof createCells>[]) {
  return arr
    .map(({ head }) => (head.sx as CSSProperties)?.width ?? '1fr')
    .join(' ')
}

export function createCell(
  node: ReactNode,
  sx?: SxProps<Theme>
): WithKey<Cell> {
  return { key: uuid.v4(), children: node, sx }
}

function getWidth({ sx }: Cell) {
  const { width } = (sx as CSSProperties | undefined) ?? {}

  if (typeof width === 'string') return width
  if (typeof width === 'number') return `${width}px`
  return 'auto'
}

export const createTemplate = (arr: Cell[]) => arr.map(getWidth).join(' ')

export function mergeSX(head: Cell[], row: Cell[]) {
  row.forEach((cell, idx) => {
    cell.sx = Object.assign({}, head[idx].sx, cell.sx)
  })
}
