import { useLayoutEffect } from 'react'
import superagent from 'superagent'
import { Fetch } from 'types'
import { token } from 'features/token'
import { MARKER_URL } from 'shared/enviroments'

export const DOMEN = MARKER_URL

export const getData = (url = '') =>
  superagent.get(`${DOMEN}${url}`).set(token.headers)

export const patchData = (url = '') =>
  superagent.patch(`${DOMEN}${url}`).set(token.headers)

export const useFetch = (fetch?: Fetch | null) =>
  useLayoutEffect(() => {
    if (!fetch) return

    const {
      method,
      url,
      data,
      query,
      onStart,
      onResponse,
      onError,
      onSuccess,
      onFail,
      onProgress,
    } = fetch

    const request = superagent(method, `${DOMEN}${url}`).set(token.headers)

    onStart && onStart()

    data && request.send(data)
    query && request.query(query)
    onProgress && request.on('progress', onProgress)
    onResponse && request.on('response', onResponse)
    onError && request.on('error', onError)

    request.then(onSuccess, onFail)

    return () => request.abort()
  }, [fetch])
