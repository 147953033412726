import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { MenuItem, Skeleton, TextField } from '@mui/material'
import {
  loading,
  enableSelection,
  catalogId,
  rootCatalogItems,
} from 'features/settings-dataset'

export const CatalogClasses = observer(() => {
  if (loading.get()) return <Skeleton variant={'button'} />
  if (!enableSelection.get()) return null

  return (
    <TextField
      label={'Каталог классов'}
      select
      value={catalogId.get()}
      onChange={action((e) => catalogId.set(e.target.value))}
    >
      {rootCatalogItems.get().map((i) => (
        <MenuItem key={i.value as string} {...i} />
      ))}
    </TextField>
  )
})
